<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.sliders.index'),
          title: 'Sliders',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <form
              action=""
              method="post"
              @submit.prevent="submit"
              v-if="formSet"
            >
              <div class="form-group">
                <label for="slider_type">Slider type *</label>
                <AdvanceSelect
                  class="advance-dpdown"
                  :options="slider_types"
                  :reduce="(item) => item.id"
                  id="slider_type"
                  label="title"
                  placeholder="Select type of slider"
                  :disabled="form.processing"
                  :class="{ 'is-invalid': form.errors.type }"
                  v-model="form.type"
                >
                </AdvanceSelect>
                <transition name="form-error">
                  <div
                    class="error"
                    v-if="form.errors.type"
                    v-text="form.errors.type"
                  ></div>
                </transition>
              </div>
              <template v-if="false">
                <div class="row">
                  <div class="col-lg-6">
                    <TextBox
                      name="title"
                      v-model="form.title"
                      :disabled="form.processing"
                      :error="form.errors.title"
                    />
                  </div>
                  <div class="col-lg-6">
                    <TextBox
                      label="Title in arabic"
                      name="title_ar"
                      v-model="form.title_ar"
                      :disabled="form.processing"
                      :error="form.errors.title_ar"
                      lang="ar"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <TextBox
                      type="textarea"
                      name="description"
                      v-model="form.description"
                      :disabled="form.processing"
                      :error="form.errors.description"
                    />
                  </div>
                  <div class="col-lg-6">
                    <TextBox
                      type="textarea"
                      label="Description in arabic"
                      name="description_ar"
                      v-model="form.description_ar"
                      :disabled="form.processing"
                      :error="form.errors.description_ar"
                      lang="ar"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <TextBox
                      name="button_text"
                      v-model="form.button_text"
                      :disabled="form.processing"
                      :error="form.errors.button_text"
                    />
                  </div>
                  <div class="col-lg-6">
                    <TextBox
                      label="Button text in arabic"
                      name="button_text_ar"
                      v-model="form.button_text_ar"
                      :disabled="form.processing"
                      :error="form.errors.button_text_ar"
                      lang="ar"
                    />
                  </div>
                </div>
              </template>
              <TextBox
                name="redirect_url"
                v-model="form.redirect_url"
                :disabled="form.processing"
                :error="form.errors.redirect_url"
              />
              <FileInput
                label="Image *"
                name="image"
                v-model="form.image"
                :fileUrl="form.image_url"
                :disabled="form.processing"
                :error="form.errors.image"
                helperText="Ideal size is 1170x400 pixels or aspect ratio of 3:1"
              />
              <FileInput
                label="Image for mobile view"
                name="image_mobile"
                v-model="form.image_mobile"
                :fileUrl="form.image_mobile_url"
                :disabled="form.processing"
                :error="form.errors.image_mobile"
                helperText="Ideal size is 1170x500 pixels or aspect ratio of 2:1"
              />
              <StatusRadio
                v-model="form.is_active"
                :disabled="form.processing"
                :error="form.errors.is_active"
              />
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import FileInput from "@/components/form/fileInput.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    AdvanceSelect: vSelect,
    FileInput,
  },
  props: {
    slider_types: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      this.form.image = null;
      this.form.image_mobile = null;
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
  computed: {},
};
</script>
