<template>
  <div v-if="hasData" class="main-wrapper">
    <template v-if="isVisibile">
      <div class="position-relative px-lg-3">
        <div
          class="swiper-button-prev d-none d-md-flex"
          ref="swiper-button-prev"
        ></div>
        <div
          class="swiper-button-next d-none d-md-flex"
          ref="swiper-button-next"
        ></div>
        <div
          class="swiper slider-container"
          ref="swiper-container"
          v-if="sliders.length"
        >
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div
              class="text-center swiper-slide"
              v-for="slider in sliders"
              :key="slider.id"
            >
              <component
                :is="
                  slider.redirect_url && slider.redirect_url != '#'
                    ? 'inertia-link'
                    : 'div'
                "
                :href="slider.redirect_url"
              >
                <img v-lazy="slider.image_url" class="img-desktop" alt="" />
                <img
                  v-lazy="slider.image_mobile_url || slider.image_url"
                  class="img-mobile"
                  :alt="`${app_name()} - ${slider.title || 'Slider'}`"
                />
              </component>
            </div>
          </div>
        </div>
      </div>
      <div
        class="swiper-pagination position-relative pt-1 d-block d-md-none"
        ref="swiper-pagination"
      ></div>
    </template>
    <InfiniteScroll
      @isvisible="getData"
      :once="true"
      style="min-height: 300px"
      v-else
    />
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination, Autoplay]);

export default {
  props: {
    sliderType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sliders: [],
      swiper: null,
      hasData: true,
      isVisibile: false,
    };
  },
  methods: {
    getData() {
      this.isVisibile = true;

      setTimeout(() => {
        axios
          .get(
            this.$route("home.api.sliders", {
              type: this.sliderType,
            })
          )
          .then((response) => {
            this.sliders = response.data.data || [];
            this.hasData = this.sliders.length > 0;
            if (!this.hasData) return;
            setTimeout(() => {
              this.initSwiper();
            }, 100);
          });
      });
    },
    initSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
      this.swiper = new Swiper(this.$refs["swiper-container"], {
        // speed: 400,
        loop: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        autoplay: {
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 5,
        pagination: {
          el: this.$refs["swiper-pagination"],
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-wrapper {
  width: 100%;
  aspect-ratio: 3 / 1;
  position: relative;
}
.slider-container {
  width: 100%;
  aspect-ratio: 3 / 1;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  .img-mobile {
    display: none;
  }
  @media (max-width: 820px) {
    aspect-ratio: 2 / 1;
    .img-mobile {
      display: block;
    }
    .img-desktop {
      display: none;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
  }
}
</style>