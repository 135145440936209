<template>
  <Modal
    :isOpen.sync="orderDetails.isOpen"
    title="Order Details"
    id="order-details-modal"
    size="medium"
    @close="$emit('close')"
  >
    <div>
      <template v-if="getObjLength(orderDetails.data) > 0">
        <table class="table">
          <tr>
            <th>Order ID</th>
            <td v-text="orderDetails.data.order_id"></td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              <template v-if="editMode">
                <form action="" @submit.prevent="save">
                  <div class="row">
                    <div class="col">
                      <select
                        class="form-control form-control-sm mb-0"
                        v-model="orderDetails.data.status"
                      >
                        <option value="pending">Pending</option>
                        <option value="delivered">Delivered</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                    <div class="col-auto p-0">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        @click.prevent="save"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </template>
              <template v-else>
                <span
                  class="badge badge-pill badge-primary"
                  :class="{
                    'badge-warning': orderDetails.data.status === 'pending',
                    'badge-success': orderDetails.data.status === 'delivered',
                    'badge-danger': orderDetails.data.status === 'cancelled',
                  }"
                  style="font-size: 0.8rem"
                  >{{ orderDetails.data.status_formatted }}</span
                >
                <button
                  class="btn btn-light btn-sm"
                  @click.prevent="editMode = true"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </template>
            </td>
          </tr>
          <tr>
            <th>Quantity</th>
            <th v-text="orderDetails.data.quantity"></th>
          </tr>
          <tr>
            <th>Price</th>
            <th v-text="orderDetails.data.price_formatted"></th>
          </tr>
          <tr>
            <th>Price in the currency ordered</th>
            <th
              v-text="orderDetails.data.ordered_price_formatted"
              class="text-success"
            ></th>
          </tr>
          <tr>
            <th>Ordered currency code</th>
            <td v-text="orderDetails.data.ordered_currency"></td>
          </tr>
          <tr>
            <th>Exchange rate when ordered</th>
            <td v-text="orderDetails.data.currency_exchange_rate"></td>
          </tr>
          <tr>
            <th>Placed on</th>
            <th v-text="orderDetails.data.created_at_human_full"></th>
          </tr>
          <tr>
            <th>Updated on</th>
            <th v-text="orderDetails.data.updated_at_human_full"></th>
          </tr>
          <tr>
            <th>Address line 1</th>
            <td v-text="orderDetails.data.address_line_1"></td>
          </tr>
          <tr>
            <th>Address line 2</th>
            <td v-text="orderDetails.data.address_line_2"></td>
          </tr>
          <tr>
            <th>City</th>
            <td v-text="orderDetails.data.city"></td>
          </tr>
          <tr>
            <th>State</th>
            <td v-text="orderDetails.data.state"></td>
          </tr>
          <tr>
            <th>Zip</th>
            <td v-text="orderDetails.data.zip"></td>
          </tr>
        </table>
        <hr />
        <h4>User details</h4>
        <table class="table">
          <tr>
            <th>User ID</th>
            <td>
              <a
                :href="
                  $route('admin.users.edit', {
                    id: orderDetails.data.user.id,
                  })
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ orderDetails.data.user.id }}
                <small class="fas fa-external-link-alt"></small>
              </a>
            </td>
          </tr>
          <tr>
            <th>Name</th>
            <td v-text="orderDetails.data.user.name"></td>
          </tr>
          <tr>
            <th>Email</th>
            <td>
              <a :href="'mailto:' + orderDetails.data.user.email">
                {{ orderDetails.data.user.email }}
                <small class="fas fa-external-link-alt"></small>
              </a>
            </td>
          </tr>
          <tr>
            <th>Mobile</th>
            <td>
              <a :href="'tel:' + orderDetails.data.user.mobile">
                {{ orderDetails.data.user.mobile }}
                <small class="fas fa-external-link-alt"></small>
              </a>
            </td>
          </tr>
          <tr>
            <th>Created at</th>
            <td v-text="orderDetails.data.user.created_at_human"></td>
          </tr>
          <tr>
            <th>Updated at</th>
            <td v-text="orderDetails.data.user.updated_at_human"></td>
          </tr>
        </table>
        <hr />
        <h4>Device details</h4>
        <table class="table">
          <tr>
            <th>IP Address</th>
            <td>
              <a
                :href="`https://www.ip2location.com/demo/${orderDetails.data.udf.ip}`"
                target="_blank"
                rel="noopener noreferrer"
                v-if="orderDetails.data.udf.ip"
              >
                {{ orderDetails.data.udf.ip }}
                <small class="fas fa-external-link-alt"></small>
              </a>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <th>Platform/OS</th>
            <td v-text="orderDetails.data.udf.platform || '-'"></td>
          </tr>
          <tr>
            <th>Browser</th>
            <td v-text="orderDetails.data.udf.browser || '-'"></td>
          </tr>
          <tr>
            <th>User agent</th>
            <td v-text="orderDetails.data.udf.user_agent || '-'"></td>
          </tr>
          <tr>
            <th>utm_source</th>
            <td v-text="orderDetails.data.udf.utm_source || '-'"></td>
          </tr>
          <tr>
            <th>utm_medium</th>
            <td v-text="orderDetails.data.udf.utm_medium || '-'"></td>
          </tr>
          <tr>
            <th>utm_campaign</th>
            <td v-text="orderDetails.data.udf.utm_campaign || '-'"></td>
          </tr>
        </table>
        <hr />
        <h4>Product details</h4>
        <table class="table">
          <tr>
            <th>Product ID</th>
            <td>
              <a
                :href="
                  $route('home.products.show', {
                    slug: orderDetails.data.product.slug,
                  })
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ orderDetails.data.product.id }}
                <small class="fas fa-external-link-alt"></small>
              </a>
            </td>
          </tr>
          <tr>
            <th>Title</th>
            <td v-text="orderDetails.data.product.title"></td>
          </tr>
          <tr>
            <th>In stock</th>
            <td>
              <span
                class="badge badge-pill badge-success"
                v-if="orderDetails.data.product.in_stock"
                >Yes</span
              >
              <span class="badge badge-pill badge-danger" v-else>No</span>
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              <span
                class="badge badge-pill badge-success"
                v-if="orderDetails.data.product.is_active"
                >Yes</span
              >
              <span class="badge badge-pill badge-danger" v-else>No</span>
            </td>
          </tr>
          <tr>
            <th>Created at</th>
            <td v-text="orderDetails.data.product.created_at_human"></td>
          </tr>
          <tr>
            <th>Updated at</th>
            <td v-text="orderDetails.data.product.updated_at_human"></td>
          </tr>
        </table>
      </template>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    orderDetails: {
      type: Object,
      default: () => ({
        isOpen: false,
        data: {},
      }),
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    save() {
      axios
        .post(this.$route("admin.orders.updateStatus"), {
          id: this.orderDetails.data.id,
          status: this.orderDetails.data.status,
        })
        .then((response) => {
          this.orderDetails.data = response.data.data;
          this.$emit("save", response.data.data);
          this.editMode = false;
        });
    },
  },
};
</script>