<template>
  <Container v-if="!noData">
    <InfiniteScroll @isvisible="getData" :once="true">
      <div class="row">
        <div class="col-lg-4 mb-3 mb-lg-0">
          <div class="title-wrapper">
            <h3 class="mb-3 text-dim">Reviews</h3>
            <h1 class="mb-0">What our clients say about us!</h1>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="box">
            <div class="header">
              <div class="icon"><i class="fa fa-quote-left"></i></div>
              <div class="navigation">
                <div class="swiper-button-prev" ref="swiper-button-prev"></div>
                <div class="swiper-button-next" ref="swiper-button-next"></div>
              </div>
            </div>
            <div class="swiper" ref="swiper-container">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide" v-for="item in data" :key="item.id">
                  <div class="review">
                    <blockquote>"{{ item.message }}"</blockquote>
                    <div class="meta">
                      <div class="avatar">
                        <img
                          v-if="item.image_url"
                          v-lazy="item.image_url"
                          class="avatar-img"
                        />
                        <ProfilePic
                          v-else
                          class="avatar-img"
                          style="border-radius: 50%"
                        />
                      </div>
                      <div class="name-wrapper">
                        <div class="name font-weight-bold">
                          {{ item.name }}
                        </div>
                        <div class="rating">
                          <PhStar weight="fill" />
                          <span v-text="item.rating"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfiniteScroll>
  </Container>
</template>
<script>
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

import ProfilePic from "@/icons/profilePic.vue";

import { PhStar } from "phosphor-vue";

Swiper.use([Navigation]);

export default {
  components: {
    ProfilePic,
    PhStar,
  },
  data() {
    return {
      data: [],
      loading: true,
      noData: false,
    };
  },
  methods: {
    getData() {
      axios
        .get(this.$route("home.api.reviews"))
        .then((response) => {
          this.data = response.data.data;
          this.noData = response.data.data.length === 0;
          setTimeout(() => {
            this.loading = false;
            this.initSwiper();
          }, 200);
        })
        .catch(() => {
          this.loading = false;
          this.noData = true;
        });
    },
    initSwiper() {
      new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title-wrapper {
  padding: 4rem 3rem;
  background: var(--primary-dark);
  border-radius: var(--border-radius-more);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1,
  h3 {
    color: var(--white);
  }
  height: 100%;
  @media (max-width: 576px) {
    padding: 3rem 2rem;
  }
}

.box {
  background: var(--card-color);
  border-radius: var(--border-radius-more);
  box-shadow: var(--box-shadow);
  padding: 3rem;
  height: 100%;
  @media (max-width: 576px) {
    padding: 2rem;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.4rem;
    .icon {
      width: 3.25rem;
      height: 3.25rem;
      font-size: 1.4rem;
      background: var(--primary);
      color: var(--primary-contrast);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow-primary);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navigation {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      justify-content: center;
      .swiper-button-prev,
      .swiper-button-next {
        position: static;
        margin-top: 0;
      }
    }
  }
  .review {
    blockquote {
      font-style: italic;
      letter-spacing: 1px;
      font-size: 1.05rem;
    }
    .meta {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar-img {
        height: 60px;
        width: 60px;
        border-radius: var(--border-radius-more);
        box-shadow: var(--box-shadow);
        object-fit: cover;
      }
    }
    .rating {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      font-weight: bold;
      svg {
        color: #ffc107;
      }
    }
  }
}
</style>