<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.users.index'),
          title: 'Users',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <form
              action=""
              method="post"
              @submit.prevent="submit"
              v-if="formSet"
            >
              <TextBox
                name="name"
                v-model="form.name"
                :disabled="form.processing"
                :error="form.errors.name"
              />
              <TextBox
                name="email"
                v-model="form.email"
                :disabled="form.processing"
                :error="form.errors.email"
              />
              <TextBox
                name="mobile"
                v-model="form.mobile"
                :disabled="form.processing"
                :error="form.errors.mobile"
              />
              <div class="row">
                <div class="col">
                  <TextBox
                    type="password"
                    name="password"
                    v-model="form.password"
                    :disabled="form.processing"
                    :error="form.errors.password"
                    :helperText="
                      editMode
                        ? 'Leave blank if you don\'t want to change password'
                        : ''
                    "
                  />
                </div>
                <div class="col-lg-6" v-if="!editMode">
                  <TextBox
                    type="password"
                    name="password_confirmation"
                    v-model="form.password_confirmation"
                    :disabled="form.processing"
                    :error="form.errors.password_confirmation"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="role">Role</label>
                <AdvanceSelect
                  class="advance-dpdown"
                  :options="user_roles"
                  :reduce="(item) => item.id"
                  id="role"
                  label="title"
                  placeholder="Select role"
                  :disabled="form.processing"
                  :class="{ 'is-invalid': form.errors.role }"
                  v-model="form.role"
                >
                </AdvanceSelect>
                <transition name="form-error">
                  <div
                    class="error"
                    v-if="form.errors.role"
                    v-text="form.errors.role"
                  ></div>
                </transition>
              </div>
              <StatusRadio
                v-model="form.is_active"
                :disabled="form.processing"
                :error="form.errors.is_active"
              />
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    AdvanceSelect: vSelect,
  },
  props: {
    user_roles: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      this.form.image = null;
      this.form.image_mobile = null;
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
  computed: {},
};
</script>
