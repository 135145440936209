<template>
  <div>
    <Container>
      <div class="wrapper">
        <div class="row">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <div
              class="swiper-container"
              ref="swiper-container"
              style="overflow: hidden; position: relative"
            >
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper" v-if="product.images.length > 0">
                <!-- Slides -->
                <div
                  class="text-center swiper-slide"
                  v-for="image in product.images"
                  :key="image.id"
                >
                  <img
                    v-lazy="image.image_url"
                    :alt="image.alt || product.title"
                    role="link"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="swiper-wrapper" v-else>
                <div class="text-center swiper-slide">
                  <img
                    v-lazy="product.thumbnail_url"
                    :alt="product.title"
                    role="link"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="swiper-pagination" ref="swiper-pagination"></div>
              <div class="swiper-button-prev" ref="swiper-button-prev"></div>
              <div class="swiper-button-next" ref="swiper-button-next"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="categories text-muted mb-1 mb-md-2">
              <inertia-link
                :href="
                  $route('home.products.index', {
                    category: product.category.slug,
                  })
                "
                class="name"
                >{{ product.category.name }}</inertia-link
              >
              <span class="mx-1">&bullet;</span>
              <inertia-link
                :href="
                  $route('home.products.index', {
                    sub_category: product.sub_category.slug,
                  })
                "
                class="name"
                >{{ product.sub_category.name }}</inertia-link
              >
            </div>
            <h2 class="product-title mb-1" v-text="product.title"></h2>
            <div class="mb-2 mb-md-3">
              <b class="text-success" v-if="product.in_stock">In stock</b>
              <b class="text-danger" v-else>Out of stock</b>
            </div>
            <div class="price-wrapper mb-4">
              <h4 class="striked-wrapper mb-0" v-if="product.cost_price">
                <span
                  class="striked"
                  v-text="product.cost_price_formatted"
                ></span>
                <b class="text-success" v-if="product.discount"
                  >{{ product.discount }}% off</b
                >
              </h4>
              <h2
                class="mb-0 price"
                v-text="product.selling_price_formatted"
              ></h2>
            </div>
            <p
              v-text="product.short_description"
              class="text-dim mb-3 mb-md-4 pb-1"
            ></p>
            <div class="mt-4 buttons" v-if="product.in_stock">
              <button
                class="btn btn-outline-primary"
                @click.prevent="addToCart"
              >
                <span v-if="countInCart(product.id) > 0">Go to cart</span>
                <span v-else>Add to cart</span>
                <PhShoppingCartSimple class="icon" weight="fill" />
              </button>
              <button class="btn btn-primary" @click.prevent="addToCart">
                <span>Buy now</span>
                <PhCreditCard class="icon" weight="fill" />
              </button>
            </div>
          </div>
          <div class="col-12 mt-3">
            <hr />
            <div class="p-0 px-md-4 pt-md-3">
              <h3 class="mb-3">Product details:</h3>
              <p v-html="product.description"></p>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <hr class="d-block d-md-none" />
    <DynamicProducts
      title="Related products"
      :api="
        $route('home.api.products', {
          category_id: product.category_id,
          hide: product.id,
        })
      "
    />
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination]);

import { PhShoppingCartSimple, PhCreditCard } from "phosphor-vue";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    PhShoppingCartSimple,
    PhCreditCard,
    DynamicProducts: () =>
      import(
        /* webpackChunkName: "products-dynamiclist" */ "./dynamicData.vue"
      ),
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 400);
  },
  methods: {
    initSwiper() {
      new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        // spaceBetween: 30,
        pagination: {
          el: this.$refs["swiper-pagination"],
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
      });
    },
    addToCart() {
      if (!this.isAuthenticated) {
        this.error_alert("Please login/register to continue");
        this.$inertia.visit(
          this.$route("auth.register", {
            redirect_url: this.$route(
              this.$route().current(),
              this.$route().params
            ),
          })
        );
        return;
      }

      if (this.countInCart(this.product.id) > 0) {
        this.$inertia.visit(this.$route("home.cart.index"));
      } else {
        this.$store.dispatch("addToCart", this.product.id).then(() => {
          this.$root.$emit("cart:updated");
          this.success_alert("Product added to cart");
          setTimeout(() => {
            this.$inertia.visit(this.$route("home.cart.index"));
          }, 400);
        });
      }
    },
  },
  computed: {
    product() {
      return this.data.data || {};
    },
    isAuthenticated() {
      return this.$page.props.isAuthenticated;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 1rem;
  background: var(--card-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  @media (max-width: 576px) {
    padding: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  .swiper-container {
    height: 520px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 992px) {
      height: 480px;
    }
    @media (max-width: 576px) {
      height: 440px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .categories {
    .name {
      font-weight: 500;
      color: var(--font-color);
      transition: var(--transition);
      opacity: 0.8;
      &:hover {
        opacity: 1;
        color: var(--primary);
      }
    }
  }

  .product-title {
    color: var(--dark);
  }

  .price-wrapper {
    .striked-wrapper {
      font-size: 1.1rem;
      font-weight: 400;
      .striked {
        opacity: 0.6;
        text-decoration: line-through;
        margin-right: 1px;
      }
    }
    .price {
      color: var(--dark);
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    .btn {
      flex: 1;
      width: 100%;
      padding: 0.4rem 1rem;
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      .btn {
        font-size: 1rem;
      }
    }
  }
}
</style>