<template>
  <Screen>
    <AppBar title="Orders" :showBackButton="false" />
    <Breadcrumbs title="Orders" />
    <div class="card">
      <div class="card-body">
        <template v-if="data.data.length > 0">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order ID</th>
                  <th>User</th>
                  <th>Product</th>
                  <th>Address</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.data" :key="item.id">
                  <td>
                    {{
                      i + 1 + (data.meta.current_page - 1) * data.meta.per_page
                    }}
                  </td>
                  <td>
                    <small v-text="item.order_id"></small>
                  </td>
                  <td>
                    <a
                      :href="
                        $route('admin.users.edit', {
                          id: item.user.id,
                        })
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                      v-text="item.user.name"
                    ></a>
                    <small class="d-block">
                      <a
                        :href="'mailto:' + item.user.email"
                        v-text="item.user.email"
                      ></a>
                      &bullet;
                      <a
                        :href="'tel:' + item.user.mobile"
                        v-text="item.user.mobile"
                      ></a>
                    </small>
                  </td>
                  <td>
                    <a
                      :href="
                        $route('home.products.show', {
                          slug: item.product.slug,
                        })
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                      class="wrap-text-2"
                    >
                      {{ item.product.title }}
                    </a>
                  </td>
                  <td>
                    <small v-text="item.address_line_1"></small>
                  </td>
                  <th v-text="item.quantity"></th>
                  <th v-text="item.price_formatted"></th>
                  <td>
                    <span
                      class="badge badge-pill badge-primary"
                      :class="{
                        'badge-warning': item.status_formatted === 'Pending',
                        'badge-success': item.status_formatted === 'Delivered',
                        'badge-danger': item.status_formatted === 'Cancelled',
                      }"
                      style="font-size: 0.8rem"
                      v-text="item.status_formatted"
                    ></span>
                  </td>
                  <td>{{ item.created_at_human }}</td>
                  <td>{{ item.updated_at_human }}</td>
                  <td>
                    <button
                      class="btn btn-light btn-sm"
                      @click.prevent="showOrderDetails(item)"
                    >
                      More info
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination :rawData="data.meta" :onClick="pageChange" />
        </template>
        <NoData title="No orders found" v-else>
          <span></span>
        </NoData>
      </div>
    </div>
    <OrderDetails
      :orderDetails="orderDetails"
      @save="updateData"
      @close="modalClosed"
    />
  </Screen>
</template>
<script>
import OrderDetails from "./orderDetails.vue";
export default {
  components: { OrderDetails },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      orderDetails: {
        isOpen: false,
        data: {},
        dataUpdated: false,
      },
    };
  },
  mounted() {},
  methods: {
    showOrderDetails(order) {
      this.orderDetails.data = order;
      this.orderDetails.isOpen = true;
      this.orderDetails.dataUpdated = false;
    },
    pageChange(page) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          page: page,
        })
      );
    },
    modalClosed() {
      if (this.orderDetails.dataUpdated) {
        this.$inertia.reload({ only: ["data"], preserveScroll: true });
      }
    },
    updateData(order) {
      this.orderDetails.data = order;
      this.orderDetails.dataUpdated = true;

      this.success_alert("Order updated successfully");
    },
  },
  computed: {},
};
</script>
