<template>
  <Screen>
    <AppBar title="App config" :showBackButton="false" />
    <Breadcrumbs title="App config" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <form action="" @submit.prevent="submit" v-if="formSet">
              <h3 class="mb-3">SEO</h3>
              <div class="row">
                <div class="col-6">
                  <TextBox
                    type="textarea"
                    label="Home page title"
                    name="page_title"
                    placeholder="Enter page title"
                    v-model="form.page_title"
                    :disabled="form.processing"
                    :error="form.errors.page_title"
                    :charactersCount="true"
                    :maxCharacters="70"
                  />
                </div>
                <div class="col-6">
                  <TextBox
                    type="textarea"
                    label="Home page title in ar"
                    name="page_title_ar"
                    placeholder="Enter page title"
                    v-model="form.page_title_ar"
                    :disabled="form.processing"
                    :error="form.errors.page_title_ar"
                    lang="ar"
                    :charactersCount="true"
                    :maxCharacters="70"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    label="Seo description meta description"
                    name="web_description"
                    v-model="form.web_description"
                    :disabled="form.processing"
                    :error="form.errors.web_description"
                    :charactersCount="true"
                    :maxCharacters="160"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    label="Seo description meta description in ar"
                    name="web_description_ar"
                    v-model="form.web_description_ar"
                    :disabled="form.processing"
                    :error="form.errors.web_description_ar"
                    lang="ar"
                    :charactersCount="true"
                    :maxCharacters="160"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    name="short_about_us"
                    v-model="form.short_about"
                    :disabled="form.processing"
                    :error="form.errors.short_about"
                    :charactersCount="true"
                    :maxCharacters="300"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    label="Short about us in ar"
                    name="short_about_ar"
                    v-model="form.short_about_ar"
                    :disabled="form.processing"
                    :error="form.errors.short_about_ar"
                    lang="ar"
                    :charactersCount="true"
                    :maxCharacters="300"
                  />
                </div>
              </div>
              <TextBox
                name="seo_tags"
                v-model="form.seo_tags"
                :disabled="form.processing"
                :error="form.errors.seo_tags"
                :charactersCount="true"
                :maxCharacters="160"
              />
              <hr />
              <h3 class="mb-3">Company contact details</h3>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    name="phone_primary"
                    v-model="form.phone_primary"
                    :disabled="form.processing"
                    :error="form.errors.phone_primary"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    name="phone_secondary"
                    v-model="form.phone_secondary"
                    :disabled="form.processing"
                    :error="form.errors.phone_secondary"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    name="email_primary"
                    v-model="form.email_primary"
                    :disabled="form.processing"
                    :error="form.errors.email_primary"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    name="email_secondary"
                    v-model="form.email_secondary"
                    :disabled="form.processing"
                    :error="form.errors.email_secondary"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    name="address_primary"
                    v-model="form.address_primary"
                    :disabled="form.processing"
                    :error="form.errors.address_primary"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    type="textarea"
                    name="address_secondary"
                    v-model="form.address_secondary"
                    :disabled="form.processing"
                    :error="form.errors.address_secondary"
                  />
                </div>
              </div>
              <hr />
              <h3 class="mb-3">Social media links</h3>
              <div class="row">
                <div class="col-lg-4">
                  <TextBox
                    label="Facebook"
                    name="facebook"
                    placeholder="Enter facebook link"
                    v-model="form.facebook"
                    :disabled="form.processing"
                    :error="form.errors.facebook"
                  />
                </div>
                <div class="col-lg-4">
                  <TextBox
                    label="Twitter"
                    name="twitter"
                    placeholder="Enter twitter link"
                    v-model="form.twitter"
                    :disabled="form.processing"
                    :error="form.errors.twitter"
                  />
                </div>
                <div class="col-lg-4">
                  <TextBox
                    label="Instagram"
                    name="instagram"
                    placeholder="Enter instagram link"
                    v-model="form.instagram"
                    :disabled="form.processing"
                    :error="form.errors.instagram"
                  />
                </div>
                <div class="col-lg-4">
                  <TextBox
                    label="Youtube"
                    name="youtube"
                    placeholder="Enter youtube link"
                    v-model="form.youtube"
                    :disabled="form.processing"
                    :error="form.errors.youtube"
                  />
                </div>
                <div class="col-lg-4">
                  <TextBox
                    label="Linkedin"
                    name="linkedin"
                    placeholder="Enter linkedin link"
                    v-model="form.linkedin"
                    :disabled="form.processing"
                    :error="form.errors.linkedin"
                  />
                </div>
                <div class="col-lg-4">
                  <TextBox
                    label="Whatsapp"
                    name="whatsapp"
                    placeholder="Enter whatsapp link"
                    v-model="form.whatsapp"
                    :disabled="form.processing"
                    :error="form.errors.whatsapp"
                  />
                </div>
              </div>
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  components: {},
  props: {
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  color: var(--dark);
}
</style>
