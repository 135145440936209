<template>
  <form action="" @submit.prevent="submit">
    <transition name="form-error">
      <div
        class="alert font-weight-bold"
        role="alert"
        :class="alerts.type"
        v-if="alerts.type && alerts.text"
        v-text="alerts.text"
      ></div>
    </transition>
    <TextBox
      type="password"
      label="Current password"
      name="current_password"
      placeholder="Enter your current password"
      v-model="form.current_password"
      :disabled="form.processing"
      :error="form.errors.current_password"
    />
    <TextBox
      type="password"
      label="New password"
      name="password"
      placeholder="Enter your new password"
      v-model="form.password"
      :disabled="form.processing"
      :error="form.errors.password"
    />
    <TextBox
      type="password"
      label="Confirm password"
      name="password_confirmation"
      placeholder="Enter your new password again"
      v-model="form.password_confirmation"
      :disabled="form.processing"
      :error="form.errors.password_confirmation"
    />
    <div class="text-center mt-4">
      <button
        class="btn btn-primary w-75"
        type="submit"
        :disabled="form.processing"
        @click.prevent="submit"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="form.processing"
        ></div>
        <span v-else>Update password</span>
      </button>
    </div>
  </form>
</template>
<script>
import APIS from "@/helpers/apis";

export default {
  data() {
    return {
      form: this.$form({
        current_password: null,
        password: null,
        password_confirmation: null,
      }),
      alerts: {
        type: null,
        text: null,
      },
    };
  },
  methods: {
    submit() {
      this.form.post(APIS.myAccount.profile.changePassword, {
        useAlerts: false,
        onBefore: () => {
          this.alerts = {
            type: null,
            text: null,
          };
        },
        onSuccess: (response) => {
          this.form.reset();
          if (response.status && response.success_msg) {
            this.alerts = {
              type: "alert-success",
              text: response.success_msg,
            };
          } else if (!response.status && response.error_msg) {
            this.alerts = {
              type: "alert-danger",
              text: response.error_msg,
            };
          }
        },
        onError: (response) => {
          if (response.status && response.success_msg) {
            this.alerts = {
              type: "alert-success",
              text: response.success_msg,
            };
            this.form.reset();
          } else if (!response.status && response.error_msg) {
            this.alerts = {
              type: "alert-danger",
              text: response.error_msg,
            };
            this.form.reset();
          }
        },
      });
    },
  },
};
</script>