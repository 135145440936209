<template>
  <div class="form-group">
    <label :for="inputid" v-if="labelText" v-text="labelText"></label>
    <div class="file-input">
      <input type="file" :id="inputid" :accept="accept" @change="onChange" />
      <div class="inner">
        <div class="preview-file" v-if="hasFile">
          <img :src="fileData.url" alt="preview" />
          <button
            type="button"
            class="remove-btn btn btn-sm btn-danger"
            v-tooltip="'Remove file'"
            @click.prevent.stop="removeFile"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="upload-prompt" v-else>
          <div class="icon">
            <i class="fas fa-upload"></i>
          </div>
          <div class="text">
            Drag and drop or
            <span class="font-weight-bold" style="text-decoration: underline"
              >browse</span
            >
          </div>
        </div>
      </div>
    </div>
    <small
      class="text-dim font-weight-bold"
      v-if="helperText"
      v-text="helperText"
    ></small>
    <transition name="form-error">
      <div class="error" v-if="error" v-text="error"></div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: [String, undefined, null],
    },
    innerLabel: {
      type: [String, undefined, null],
    },
    value: null,
    placeholder: {
      type: [String, undefined, null],
    },
    accept: {
      type: [String, undefined, null],
      default: "image/*",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    inputClass: {
      type: [String, Array, Object],
      default: null,
    },
    error: {
      type: [String, Object, undefined, null],
      default: null,
    },
    helperText: null,
    fileUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fileData: {
        url: null,
        name: null,
        size: null,
        type: null,
        rawFile: null,
      },
      hasFile: false,
    };
  },
  mounted() {
    if (this.fileUrl) {
      this.fileData.url = this.fileUrl;
      this.hasFile = true;
    }
  },
  methods: {
    onChange(event) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        this.fileData.url = reader.result;
        this.fileData.name = event.target.files[0].name;
        this.fileData.size = event.target.files[0].size;
        this.fileData.type = event.target.files[0].type;
        this.fileData.rawFile = event.target.files[0];
        this.hasFile = true;
        this.$emit("input", event.target.files[0]);
      };
    },
    removeFile() {
      this.fileData.url = null;
      this.fileData.name = null;
      this.fileData.size = null;
      this.fileData.type = null;
      this.fileData.rawFile = null;
      this.hasFile = false;
      this.$emit("input", null);
    },
  },
  computed: {
    inputid() {
      return this.id || (Math.random() + 1).toString(36).substring(2);
    },
    readableName() {
      // if name has '-' or '_' replace all with space
      let n = this.name;
      n = n.replace(/[-_]/g, " ");
      // capitalize first letter
      n = n.charAt(0).toUpperCase() + n.slice(1);
      return n;
    },
    labelText() {
      let l = this.label;
      if (l == undefined) return this.readableName;
      // check if label is null or empty
      if (l == null || l == "" || l.length == 0) return null;
      // return label
      return l;
    },
  },
};
</script>
<style lang="scss" scoped>
.file-input {
  position: relative;
  padding: 1.8rem 1rem;
  border: 2px dashed var(--primary);
  border-radius: var(--border-radius);
  background-color: rgba(var(--primary-rgb), 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .inner {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: rgba(var(--primary-rgb), 0.8);
    .text {
      word-break: break-all;
    }
    .preview-file {
      position: relative;
      width: min(100%, 200px);
      min-height: 100px;
      height: min(100%, 200px);
      background: var(--white);
      padding: 0.5rem;
      border-radius: var(--border-radius);
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        max-width: 100%;
        border-radius: calc(var(--border-radius) / 2);
      }
      .remove-btn {
        padding: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        display: grid;
        place-content: center;
        line-height: unset;
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 3;
      }
    }
  }
  &:hover {
    background-color: rgba(var(--primary-rgb), 0.2);
    .inner {
      color: rgba(var(--primary-rgb), 1);
    }
  }
}
.custom-file-label {
  color: var(--light);
  cursor: pointer;
}
</style>