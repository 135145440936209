<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.currencies.index'),
          title: 'Currencies',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form
              action=""
              method="post"
              @submit.prevent="submit"
              v-if="formSet"
            >
              <div class="alert alert-info">
                Update the Exchange rate frequently to avoid incorrect products
                prices.
              </div>
              <h4 class="mb-3">
                Default currency:
                <b>{{ defaultCurrency.code }} - {{ defaultCurrency.symbol }}</b>
              </h4>
              <TextBox
                name="code"
                v-model.trim="form.code"
                :disabled="form.processing"
                :error="form.errors.code"
                helperText="Eg. USD"
              />
              <TextBox
                name="display_name"
                v-model="form.display_name"
                :disabled="form.processing"
                :error="form.errors.display_name"
                helperText="Eg. USD - $"
              />
              <TextBox
                name="exchange_rate"
                v-model="form.exchange_rate"
                :disabled="form.processing"
                :error="form.errors.exchange_rate"
                helperText="Enter upto 4 decimal places for precise conversion rates. Eg. 1.2345"
              />
              <p>
                Exchange rate:
                <a
                  :href="converterLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ converterLink }}
                </a>
              </p>
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
    defaultCurrency: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
  computed: {
    converterLink() {
      let url =
        "https://xe.com/currencyconverter/convert/?Amount=1&From=USD&To=";

      let to = this.defaultCurrency.code || "USD";

      if (this.form.code && this.form.code.length >= 3) {
        to = this.form.code;
      }

      return url + to;
    },
  },
};
</script>
