const localStorageKey = 'appTheme';
let matchMediaListner = null;

const state = {
    appTheme: getTheme(),
    appThemeExact: 'light',
    showFloatingCta: true,
    floatingCtaHeight: '0px',
    desktopView: true,
    deviceType: null,

    currencies: [],
};

function getTheme() {
    if (localStorage.getItem(localStorageKey) && ['dark', 'light'].includes(localStorage.getItem(localStorageKey))) {
        return localStorage.getItem(localStorageKey);
    } else {
        // set theme to system default
        localStorage.setItem(localStorageKey, 'system-default');
        return 'system-default';
    }
}

function changeTheme(commit) {
    const payload = getTheme();
    let themeName = payload;

    if (payload == 'system-default') {
        themeName = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    // set attribute for html tag
    document.body.setAttribute('data-mode', themeName);
    commit('appTheme', payload);
    commit('appThemeExact', themeName);
}

const getters = {
    appTheme: state => state.appTheme,
    appThemeExact: state => state.appThemeExact,
    showFloatingCta: state => state.showFloatingCta,
    floatingCtaHeight: state => state.floatingCtaHeight,
    desktopView: state => state.desktopView,
    deviceType: state => state.deviceType,
    currencies: state => state.currencies,
};

const mutations = {
    appTheme: (state, payload) => (state.appTheme = payload),
    appThemeExact: (state, payload) => (state.appThemeExact = payload),
    showFloatingCta: (state, payload) => (state.showFloatingCta = payload),
    floatingCtaHeight: (state, payload) => (state.floatingCtaHeight = payload),
    desktopView: (state, payload) => (state.desktopView = payload),
    deviceType: (state, payload) => (state.deviceType = payload),
    setCurrencies: (state, payload) => (state.currencies = payload),
}

const actions = {
    // theme
    setTheme({ commit }, payload) {
        if (!payload) {
            payload = getTheme();
        }
        localStorage.setItem(localStorageKey, payload);
        changeTheme(commit);
        if (payload == 'system-default') {
            matchMediaListner = window.matchMedia(
                "(prefers-color-scheme: dark)"
            );
            try {
                // Chrome & Firefox
                matchMediaListner.addEventListener("change", () => changeTheme(commit));
            } catch (e1) {
                try {
                    // Safari
                    matchMediaListner.addListener(() => changeTheme(commit));
                } catch {
                    // error
                }
            }
        } else {
            if (matchMediaListner) {
                matchMediaListner.removeEventListener("change", () => changeTheme(commit));
                matchMediaListner.removeListener(() => changeTheme(commit));
            }
        }
    },
    toggleTheme({ getters, dispatch }) {
        dispatch('setTheme', getters.appThemeExact == 'dark' ? 'light' : 'dark');
    },
    // cta
    setFloatingCta({ commit }, payload) {
        commit('showFloatingCta', payload);
    },
    setFloatingCtaHeight({ commit }, payload) {
        commit('floatingCtaHeight', payload);
    },
    // device info
    viewportUpdate({ commit }, payload) {
        commit('desktopView', payload);
    },
    setDeviceType({ commit }, payload) {
        commit('deviceType', payload);
    },

    async fetchCurrencies({ commit }, defaultCurrency) {
        const response = await axios.get(route('home.api.currencies'));
        commit('setCurrencies', [defaultCurrency, ...response.data.data]);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};