<template>
  <Container>
    <SectionHeader title="Products" />
    <div class="row">
      <div class="col-xl-2">
        <Filters />
      </div>
      <div class="col">
        <div class="mb-4 d-flex">
          <SearchBar
            v-model="form.search"
            @onSubmit="searchSubmit"
            @onClear="searchSubmit"
            placeholder="Search in all medical equipments"
            class="mb-0 w-100"
            style="margin-bottom: 0 !important"
          />
          <button
            type="button"
            class="btn btn-light ml-2 d-inline d-xl-none"
            @click.prevent="$root.$emit('openFilterForm')"
          >
            <i class="fa fa-filter"></i>
          </button>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-lg-6 mb-4"
            v-for="item in data.data"
            :key="item.id"
          >
            <Item :data="item" />
          </div>
        </div>
        <Pagination :rawData="data.meta" :onClick="pageChange" />
      </div>
    </div>
  </Container>
</template>
<script>
import Item from "./components/item.vue";
import Filters from "./components/filters.vue";

export default {
  components: {
    Item,
    Filters,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        search: "",
      }),
    };
  },
  mounted() {
    this.form.search = this.$route().params.search || null;
  },
  methods: {
    pageChange(page) {
      const params = {
        ...this.$route().params,
        page: page,
      };

      this.$inertia.visit(this.$route(this.$route().current(), params));
    },
    searchSubmit() {
      const params = {
        ...this.$route().params,
        page: 1,
        search: this.form.search,
      };

      this.$inertia.visit(this.$route(this.$route().current(), params));
    },
  },
};
</script>