<template>
  <Screen title="Unauthorized">
    <AppBar :showBackButton="false" />
    <h1 class="mt-5">Unauthorized!</h1>
  </Screen>
</template>
<script>
export default {
  title: "Unauthorized",
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  watch: {},
  created() {},
  mounted() {},
};
</script>
