<template>
  <div class="sticky-top d-none d-xl-block" ref="component">
    <h4 class="title">
      Filter
      <div>
        <button
          type="button"
          class="btn btn-light btn-sm"
          @click="ResetFilters"
        >
          Reset
        </button>
        <button
          type="button"
          class="btn btn-danger-outline ml-1 btn-sm d-inline d-xl-none"
          @click="ClosePopup"
        >
          Close
        </button>
      </div>
    </h4>
    <div class="accordion">
      <div class="accordion-item">
        <p class="title">
          <button
            class="btn btn-link w-100"
            type="button"
            data-toggle="collapse"
            data-target="#categoriesFilter"
          >
            Categories
          </button>
        </p>
        <div id="categoriesFilter" class="collapse show">
          <div class="accordion-content">
            <div v-for="category in categories" :key="category.id">
              <input
                type="radio"
                :id="'category-' + category.id"
                name="category"
                class="d-none"
                :value="category.slug"
                :checked="selectedCategory == category.id"
                @change="categorySelect(category)"
              />
              <label
                :for="`category-${category.id}`"
                class="cursor-pointer ml-1"
                :class="{
                  'text-primary': selectedCategory == category.id,
                }"
              >
                {{ category.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <p class="title">
          <button
            class="btn btn-link w-100"
            type="button"
            data-toggle="collapse"
            data-target="#subCategoriesFilter"
          >
            Sub Categories
          </button>
        </p>
        <div id="subCategoriesFilter" class="collapse show">
          <div class="accordion-content">
            <div v-for="subCategory in subCategories" :key="subCategory.id">
              <input
                type="radio"
                class="d-none"
                :id="'subCategory-' + subCategory.id"
                name="subCategory"
                :value="subCategory.slug"
                :checked="selectedSubCategory == subCategory.id"
                @change="subCategorySelect(subCategory)"
              />
              <label
                class="ml-1 cursor-pointer"
                :for="`subCategory-${subCategory.id}`"
                :class="{
                  'text-primary': selectedSubCategory == subCategory.id,
                }"
              >
                {{ subCategory.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <p class="title">
          <button
            class="btn btn-link w-100"
            type="button"
            data-toggle="collapse"
            data-target="#priceFilter"
          >
            Price
          </button>
        </p>
        <div id="priceFilter" class="collapse show">
          <div class="accordion-content">
            <form @submit.prevent="priceSelected">
              <div class="row m-0">
                <div class="col p-0">
                  <input
                    type="text"
                    v-model.number.trim="priceFilter.min"
                    class="form-control form-control-sm"
                    placeholder="minimum"
                  />
                </div>
                <div class="col px-1">
                  <input
                    type="text"
                    v-model.number.trim="priceFilter.max"
                    class="form-control form-control-sm"
                    placeholder="maximum"
                  />
                </div>
                <div class="col-auto p-0">
                  <button type="submit" class="btn btn-primary btn-sm">
                    apply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {
    $("body").removeClass("modal-open");
  },
  beforeDestroy() {
    $("body").removeClass("modal-open");
  },
  data() {
    return {
      priceFilter: {
        min: null,
        max: null,
      },
      bkhs: ["1BHK", "2BHK", "3BHK", "4BHK", "5BHK"],
      selectedBhk: "1BHK",
    };
  },
  mounted() {
    this.$root.$on("openFilterForm", () => this.OpenPopup());

    if (
      this.$page.props.filterOptions.price.min &&
      this.$page.props.filterOptions.price.max
    ) {
      this.priceFilter.min = this.$page.props.price.filterOptions.min;
      this.priceFilter.max = this.$page.props.price.filterOptions.max;
    }
  },
  methods: {
    ClosePopup() {
      $("body").removeClass("modal-open");
      $(this.$refs["component"]).removeClass("active");
    },
    ResetFilters() {
      this.ClosePopup();
      this.info_alert("Filters removed");
      this.$inertia.visit(this.$route(this.$route().current()));
    },
    OpenPopup() {
      $("body").addClass("modal-open");
      $(this.$refs["component"]).addClass("active");
    },

    categorySelect(category) {
      const params = {
        ...this.$route().params,
        page: 1,
        category: category.slug,
        sub_category: null,
      };
      this.$inertia.visit(this.$route(this.$route().current(), params));
    },
    subCategorySelect(subCategory) {
      const params = {
        ...this.$route().params,
        page: 1,
        sub_category: subCategory.slug,
      };
      this.$inertia.visit(this.$route(this.$route().current(), params));
    },
    priceSelected() {
      if (this.priceFilter.min && this.priceFilter.max) {
        const params = {
          ...this.$route().params,
          page: 1,
          price_min: this.priceFilter.min,
          price_max: this.priceFilter.max,
        };

        this.$inertia.visit(this.$route(this.$route().current(), params));
      } else {
        this.error_alert("Please enter both minimum and maximum values");
      }
    },
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    subCategories() {
      if (this.selectedCategory == "" || this.selectedCategory == null) {
        // get all subcategories from all categories
        let subCategories = [];
        this.categories.forEach((category) => {
          subCategories = subCategories.concat(category.sub_categories);
        });
        return subCategories;
      }

      // get subcategories from selected category
      return this.categories.find(
        (category) => category.id == this.selectedCategory
      ).sub_categories;
    },
    selectedCategory() {
      return this.$page.props.filterOptions.category;
    },
    selectedSubCategory() {
      return this.$page.props.filterOptions.subCategory;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion {
  .accordion-item {
    border-bottom: 2px solid var(--lighter);
    margin-bottom: 6px;
    .accordion-content {
      padding: 5px 3px;
    }
    .title {
      margin-bottom: 4px;
      button {
        position: relative;
        text-align: left;
        text-decoration: none !important;
        &[data-toggle="collapse"]:after {
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          position: absolute;
          font-size: 18px;
          line-height: 22px;
          right: 20px;
          top: calc(50% - 10px);
          transition: transform 150ms linear;
          color: currentColor;
          opacity: 0.8;
        }
        &.hasFilter:before {
          font-family: "Font Awesome 5 Free";
          content: "\f111";
          position: absolute;
          font-size: 10px;
          line-height: 22px;
          right: calc(20px + 22px);
          top: calc(50% - 10px);
          transition: transform 150ms linear;
          color: var(--success);
          opacity: 0.8;
        }
        &[data-toggle="collapse"].collapsed:after {
          transform: rotate(180deg);
        }
        &:not(.collapsed) {
          color: var(--dark);
        }
        &:hover {
          background: #9c9c9c17;
          color: var(--dark);
        }
      }
    }
  }
}
.sticky-top {
  padding-right: 10px;
  top: 100px;
  z-index: 500;
  max-height: 80vh;
  overflow: auto;
  @media (max-width: 1200px) {
    display: block !important;
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background: var(--white);
    padding: 20px;
    max-height: 93%;
    z-index: 992;
    transition: all 0.7s cubic-bezier(0.2, 1, 0.22, 1);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
    transform-style: preserve-3d;
    &.active {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }

    * {
      transform: translate3d(5%, 0, 0);
      transition: all 0.6s cubic-bezier(0.2, 1, 0.22, 1);
      transform-style: preserve-3d;
      transition-delay: 0.1s;
    }

    &.active * {
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>