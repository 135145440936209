<template>
  <AccountLayout>
    <div>
      <h2 class="mb-1">Change Password</h2>
      <p class="text-dim mb-4">
        Enter your current password and a new password to change your password.
      </p>
      <div class="row">
        <div class="col-lg-8">
          <form action="" method="post" @submit.prevent="submit">
            <TextBox
              type="password"
              name="current_password"
              v-model="form.current_password"
              :disabled="form.processing"
              :error="form.errors.current_password"
            />
            <TextBox
              type="password"
              name="new_password"
              v-model="form.new_password"
              :disabled="form.processing"
              :error="form.errors.new_password"
            />
            <TextBox
              type="password"
              name="new_password_confirmation"
              v-model="form.new_password_confirmation"
              :disabled="form.processing"
              :error="form.errors.new_password_confirmation"
            />
            <div class="mt-4 text-center">
              <button
                class="btn btn-primary w-25"
                type="submit"
                :disabled="form.processing"
                @click.prevent="submit"
              >
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  v-if="form.processing"
                ></div>
                <span v-else>Update password</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AccountLayout>
</template>
<script>
import AccountLayout from "./layout.vue";
export default {
  components: {
    AccountLayout,
  },
  props: {
    submitUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
};
</script>