<template>
  <div class="tabs">
    <div class="tabs-header-wrapper">
      <div class="tabs-header" ref="header">
        <component
          :is="tab.href ? 'a' : 'div'"
          :href="tab.href"
          role="button"
          class="tabs-header-item"
          v-for="tab in transformTabs"
          :key="tab.id"
          :class="{ active: activeTab.id == tab.id, disabled: tab.disabled }"
          @click.prevent="selectTab(tab)"
          :ref="'tab-' + tab.id"
        >
          <span class="title">{{ tab.title }}</span>
        </component>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";

export default {
  props: {
    tabs: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: {},
      resizeListener: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.bringTabToCenter();
      }, 50);
    });
    if (!this.resizeListener) {
      this.resizeListener = window.addEventListener(
        "resize",
        debounce(() => {
          this.bringTabToCenter();
        })
      );
    }
  },
  methods: {
    selectTab(thistab) {
      // remove active from all tabs
      this.transformTabs.forEach((tab) => {
        tab.active = false;
        if (tab.id == thistab.id) {
          tab.active = true;
        }
      });
      this.activeTab = thistab;
      // bring in viewport
      this.$nextTick(() => {
        this.bringTabToCenter();
      });
      this.$emit("selected", thistab);
    },
    bringTabToCenter() {
      if (!this.activeTab.id) {
        this.getActiveTab();
        // returning early because it'll be called again when the active tab is set
        return;
      }
      // scroll tab to center of viewport so that left and right tabs are always visible
      const tabEl = this.$refs[`tab-${this.activeTab.id}`][0];
      const tabHeaderEl = this.$refs.header;

      let tabHeaderWidth = window.innerWidth;
      try {
        if (typeof tabHeaderEl == "object" && tabHeaderEl.length) {
          tabHeaderWidth = tabHeaderEl[0].offsetWidth;
        } else {
          tabHeaderWidth = tabHeaderEl.offsetWidth;
        }
      } catch {
        tabHeaderWidth = window.innerWidth;
      }

      const tabWidth = tabEl.offsetWidth || 0;
      const tabLeft = tabEl.offsetLeft || 0;

      const scrollLeft = tabLeft - tabHeaderWidth / 2 + tabWidth / 2;
      tabHeaderEl.scrollLeft = scrollLeft;
    },
    getActiveTab() {
      const active = this.tabs.find((tab) => tab.active);
      if (active && active.id) {
        this.activeTab = active;
        return active;
      }
    },
  },
  computed: {
    transformTabs() {
      return this.tabs.map((tab) => {
        const tabid = tab.id || tab.title.toLowerCase().replace(/\s/g, "-");
        const tabData = {
          ...tab,
          ...{
            id: tabid,
            title: tab.title,
            active: tab.active || false,
            disabled: tab.disabled || false,
          },
        };
        if (tab.active) {
          this.activeTab = tabData;
        }
        return tabData;
      });
    },
  },
  beforeDestroy() {
    if (this.resizeListener) {
      window.removeEventListener("resize", this.resizeListener);
    }
  },
};
</script>
<style lang="scss" scoped>
.tabs-header-wrapper {
  height: 46px;
  line-height: 46px;
  border-bottom: 3px solid var(--lighter);
  @media (max-width: 576px) {
    height: 44px;
    line-height: 44px;
  }
  .tabs-header {
    display: flex;
    min-height: inherit;
    align-items: center;
    overflow: auto;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    padding-bottom: 0.4rem;
    position: relative;
    z-index: 2;
    .tabs-header-item {
      padding: 0 1.5rem;
      font-weight: bold;
      font-size: 0.9rem;
      color: var(--gray);
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.3s ease;
      &:hover,
      &:focus {
        color: var(--dark);
      }
      &.active {
        color: var(--primary-dark);
        font-weight: bold;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: var(--primary-dark);
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
      @media (max-width: 768px) {
        padding: 0 1.2rem;
      }
      @media (max-width: 576px) {
        padding: 0 1rem;
      }
    }
  }
}
.content {
  padding: 1rem 0.4rem;
  padding-top: 1.8rem;
}
</style>