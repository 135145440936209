<template>
  <Container class="text-center mb-0 mb-lg-3">
    <SectionHeader title="Our Services" />
    <div class="row">
      <div
        class="col-md-4 mb-3 mb-lg-0 item-wrapper"
        v-for="item in data"
        :key="item.id"
      >
        <div class="item">
          <div class="icon">
            <img v-lazy="item.icon" :alt="item.title" />
          </div>
          <h4 v-text="item.title"></h4>
          <p
            class="mb-0 text-dim"
            style="font-size: 85%"
            v-text="item.description"
          ></p>
        </div>
      </div>
    </div>
  </Container>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          id: 1,
          title: "Snoring and Sleep Apnea",
          icon: this.asset("images/services/Snoring and Sleep Apnea.png"),
          description:
            "When snoring is accompanied by choking, panting or breathing pauses at night, you might consider sleep apnea. It is a condition that can cause you to lose sleep and wake up later than you would have expected.",
        },
        {
          id: 2,
          title: "Efficient treatment against OSA",
          icon: this.asset(
            "images/services/Efficient treatment against OSA.png"
          ),
          description:
            "If you have a sleep apnea, you might consider an OSA (Obstructive Sleep Apnea) treatment. It is a treatment that helps you to get back to sleep faster and to avoid the risk of sleep apnea.",
        },
        {
          id: 3,
          title: "Better breathing, night and day",
          icon: this.asset("images/services/Better breathing.png"),
          description:
            "Your treatment follows you everywhere thanks to our travel accessories. You can also use our sleep mask to help you sleep better.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  padding: 1rem;
  border-right: 2px solid var(--lighter);

  &:last-child {
    border-right: none;
  }

  @media (max-width: 576px) {
    border-right: none;
  }
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: var(--card-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-sm);
    height: 100px;
    width: 110px;
    margin-bottom: 1.26rem;
    border: 1px solid var(--lighter);
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }
  h4 {
    font-weight: bold;
    font-family: var(--font-family);
    font-size: 1.24rem;
    letter-spacing: 1px;
    margin-bottom: 0.6rem;
    color: var(--font-color);
  }
}
</style>