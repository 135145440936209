<template>
  <inertia-link
    class="product-item"
    :href="
      $route('home.products.show', {
        slug: data.slug,
      })
    "
  >
    <div class="fluid-img-container">
      <div class="badge badge-success" v-if="data.cost_price && data.discount">
        {{ data.discount }}% off
      </div>
      <AddToCart @click.stop="" :productId="data.id" :inStock="data.in_stock" />
      <img v-lazy="data.thumbnail_url" :alt="data.title" />
    </div>
    <div class="content-wrapper">
      <div class="content">
        <inertia-link
          :href="
            $route('home.products.index', {
              sub_category: data.sub_category.slug,
            })
          "
          class="category text-muted"
          v-if="data.sub_category"
          v-text="data.sub_category.name"
        ></inertia-link>
        <h1 class="title wrap-text-2" v-text="data.title"></h1>
        <p
          class="description text-dim wrap-text-2"
          v-text="data.short_description"
        ></p>
      </div>
      <div class="buttons">
        <div class="price-wrapper">
          <div class="price">
            <h4 class="striked-wrapper mb-0" v-if="data.cost_price">
              <span class="striked" v-text="data.cost_price_formatted"></span>
              <b class="text-success" v-if="data.discount"
                >{{ data.discount }}% off</b
              >
            </h4>
            <h3 class="mb-0" v-text="data.selling_price_formatted"></h3>
          </div>
        </div>
        <button class="btn btn-primary">Buy Now</button>
      </div>
    </div>
  </inertia-link>
</template>
<script>
import { PhShoppingCartSimple } from "phosphor-vue";

import AddToCart from "./addToCart";

export default {
  components: {
    PhShoppingCartSimple,
    AddToCart,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isAuthenticated() {
      return this.$page.props.isAuthenticated;
    },
  },
};
</script>
<style lang="scss" scoped>
.product-item {
  color: var(--font-color);
  text-decoration: none;
  display: block;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: var(--card-color);
  transition: var(--transition);
  .fluid-img-container {
    .badge {
      position: absolute;
      z-index: 5;
      top: 20px;
      left: 15px;
      padding-right: 0.6em;
      padding-left: 0.6em;
      border-radius: 5px;
    }
  }
  .content-wrapper {
    padding: 1.2rem;
  }
  .content {
    .category {
      font-size: 0.9rem;
      font-weight: 500;
      padding-left: 0.1rem;
      &:hover {
        color: var(--primary) !important;
      }
    }
    .title {
      transition: var(--transition);
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--dark);
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.5rem;
    .price-wrapper {
      .price {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .striked-wrapper {
          font-size: 0.9rem;
          font-weight: 400;
          .striked {
            opacity: 0.6;
            text-decoration: line-through;
            margin-right: 1px;
          }
        }
      }
      .meta {
        font-size: 86%;
        font-weight: 500;
        opacity: 0.6;
        text-transform: uppercase;
      }
    }
    .btn {
      box-shadow: var(--box-shadow-primary);
      @media (min-width: 768px) {
        padding: 0.275rem 1.4rem;
      }
    }
  }
  &:hover {
    box-shadow: var(--box-shadow-light);
    .content .title {
      color: var(--primary);
    }
  }
}
</style>