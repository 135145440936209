<template>
  <Screen>
    <AppBar :showBackButton="false" />
    <div class="content">
      <h3 class="mt-0 mt-lg-4">
        <span class="text-dim">Hello</span>
        {{ user.name }}
      </h3>
      <div class="alert alert-danger mt-4 mb-0" v-if="currencyCounts > 0">
        <h4 class="mb-2">
          <small class="fa fa-exclamation-circle"></small> Immediate action
          required
        </h4>
        <hr />
        <p class="mb-2">
          <b>"{{ currencyCounts }}"</b> currencies haven't been updated for more
          than 2 days. <br />
          Please update them now to avoid any issues with products prices.
        </p>
        <a
          :href="$route('admin.currencies.index')"
          class="btn btn-primary btn-sm px-3"
          >View <small class="fa fa-arrow-right ml-1"></small
        ></a>
      </div>
      <div class="overview-container">
        <div class="box" style="--bg: #c31c1c29">
          <div class="icon">
            <PhWarningCircle weight="duotone" />
          </div>
          <div class="title">
            <h1 class="mb-1">{{ counts.pending_orders }}</h1>
            <h3 class="mb-0 text-dim">Pending orders</h3>
          </div>
        </div>
        <div class="box" style="--bg: #c3aa1c29">
          <div class="icon">
            <PhHeadset weight="duotone" />
          </div>
          <div class="title">
            <h1 class="mb-1">{{ counts.new_enquiries }}</h1>
            <h3 class="mb-0 text-dim">New contact enquiries</h3>
          </div>
        </div>
        <div class="box" style="--bg: #1cc32d29">
          <div class="icon">
            <PhCalendarCheck weight="duotone" />
          </div>
          <div class="title">
            <h1 class="mb-1">{{ counts.orders_this_month }}</h1>
            <h3 class="mb-0 text-dim">
              Orders in
              <span class="font-weight-bold">
                "{{
                  new Date().toLocaleDateString("en-US", { month: "short" })
                }}"
              </span>
            </h3>
          </div>
        </div>
        <div class="box" style="--bg: #21e1b83b">
          <div class="icon">
            <PhCalendarCheck weight="duotone" />
          </div>
          <div class="title">
            <h1 class="mb-1">{{ counts.users_this_month }}</h1>
            <h3 class="mb-0 text-dim">
              New users in
              <span class="font-weight-bold">
                "{{
                  new Date().toLocaleDateString("en-US", { month: "short" })
                }}"
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-xl-7 col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h4>Pending orders</h4>
              <template v-if="orders.data.length">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Placed on</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(order, i) in orders.data" :key="order.id">
                        <td>
                          {{ i + 1 }}
                        </td>
                        <td>
                          <a
                            :href="
                              $route('admin.users.edit', {
                                id: order.user.id,
                              })
                            "
                            target="_blank"
                            rel="noopener noreferrer"
                            v-text="order.user.name"
                          ></a>
                          <small class="d-block">
                            <a
                              :href="'mailto:' + order.user.email"
                              v-text="order.user.email"
                            ></a>
                            &bullet;
                            <a
                              :href="'tel:' + order.user.mobile"
                              v-text="order.user.mobile"
                            ></a>
                          </small>
                        </td>
                        <td>
                          <a
                            :href="
                              $route('home.products.show', {
                                slug: order.product.slug,
                              })
                            "
                            target="_blank"
                            rel="noopener noreferrer"
                            class="wrap-text-2"
                          >
                            {{ order.product.title }}
                          </a>
                        </td>
                        <th v-text="order.quantity"></th>
                        <th v-text="order.price_formatted"></th>
                        <td>
                          <span
                            class="badge badge-pill badge-primary"
                            :class="{
                              'badge-warning': order.status === 'pending',
                              'badge-success': order.status === 'delivered',
                              'badge-danger': order.status === 'cancelled',
                            }"
                            v-text="order.status_formatted"
                          ></span>
                        </td>
                        <td>{{ order.created_at_human }}</td>
                        <td>
                          <button
                            class="btn btn-light btn-sm"
                            @click.prevent="showOrderDetails(order)"
                          >
                            More info
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <inertia-link
                  :href="$route('admin.orders.index')"
                  class="btn btn-block btn-outline"
                >
                  <span>View all</span>
                  <small class="fas fa-arrow-right ml-2"></small>
                </inertia-link>
              </template>
              <NoData title="No data available" v-else>
                <span></span>
              </NoData>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-md-6">
          <div class="card">
            <div class="card-body">
              <h4 class="mb-3">Contact enquiries</h4>
              <template v-if="contactUs.data.length">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in contactUs.data" :key="item.id">
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.email }}
                        </td>
                        <td>
                          {{ item.mobile }}
                        </td>
                        <td>{{ item.time_ago }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <inertia-link
                  :href="$route('admin.contactus')"
                  class="btn btn-block btn-outline"
                >
                  <span>View all</span>
                  <small class="fas fa-arrow-right ml-2"></small>
                </inertia-link>
              </template>
              <NoData title="No new enquiries" v-else>
                <span></span>
              </NoData>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OrderDetails :orderDetails="orderDetails" @save="updateData" />
  </Screen>
</template>
<script>
import { PhWarningCircle, PhHeadset, PhCalendarCheck } from "phosphor-vue";

import OrderDetails from "../orders/orderDetails.vue";

export default {
  title: "Dashboard",
  components: {
    PhWarningCircle,
    PhHeadset,
    PhCalendarCheck,
    OrderDetails,
    // Chart: () => import(/* webpackChunkName: "chart" */ "@/components/chart"),
  },
  props: {
    orders: {
      type: [Array, Object],
      default: () => ({}),
    },
    contactUs: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      counts: {
        pending_orders: 0,
        new_enquiries: 0,
        orders_this_month: 0,
        users_this_month: 0,
      },
      orderDetails: {
        isOpen: false,
        data: {},
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getCounts();
    }, 500);
  },
  methods: {
    showOrderDetails(order) {
      this.orderDetails.data = order;
      this.orderDetails.isOpen = true;
    },
    updateData() {
      this.orderDetails.isOpen = true;
      this.$inertia.reload({ only: ["orders"], preserveScroll: true });
      this.success_alert("Order updated successfully");
    },
    getCounts() {
      axios.get(this.$route("admin.index.dataCounts")).then((res) => {
        this.counts = res.data;
      });
    },
  },
  computed: {
    user() {
      return this.$page.props.user;
    },
    currencyCounts() {
      return this.$page.props.new_counts.currency || 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.overview-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;

  @media (max-width: 660px) {
    grid-template-columns: 1fr 1fr;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 28px;
    background-color: var(--bg, var(--primary-with-more-opacity));
    color: var(--dark);
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      width: 74px;
      height: 74px;
      background: var(--bg, var(--primary-with-more-opacity));
      border-radius: 50%;
      right: -12px;
      bottom: -12px;
    }
    .icon {
      font-size: 2.7rem;
      margin-bottom: 0.1rem;
    }
    .title {
      text-align: center;
      h3 {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    @media (max-width: 576px) {
      border-radius: 18px;
      .icon {
        font-size: 2rem;
      }
      .title {
        h1 {
          font-size: 1.5rem;
        }
        h3 {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>