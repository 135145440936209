<template>
  <Container v-if="hasData">
    <SectionHeader :title="title || 'Products'" :button="button" />
    <InfiniteScroll v-if="loading" :once="true" @isvisible="getData">
      <div class="wrapper">
        <ItemLoading class="item" v-for="i in 4" :key="i" />
      </div>
    </InfiniteScroll>
    <div class="wrapper" v-else>
      <Item
        class="item"
        v-for="product in products"
        :key="product.id"
        :data="product"
      />
    </div>
  </Container>
</template>
<script>
import Item from "./components/item.vue";
import ItemLoading from "./components/itemLoading.vue";

export default {
  props: {
    api: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    button: {
      type: [String, Object],
      default: undefined,
    },
  },
  components: {
    Item,
    ItemLoading,
  },
  data() {
    return {
      products: [],
      loading: true,
      hasData: true,
    };
  },
  methods: {
    getData() {
      if (!this.api) return;

      axios.get(this.api).then((response) => {
        this.products = response.data.data;
        this.hasData = response.data.data.length > 0;

        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.2rem;
  padding: 1.2rem;
  @media (max-width: 768px) {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: no-wrap;
    overflow-x: auto;
    margin-left: -14px;
    margin-right: -14px;
    .item {
      max-width: min(calc(100vw - 100px), 380px);
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
</style>