<template>
  <div class="form-wrapper">
    <inertia-link :href="$route('home.index')">
      <img
        :src="asset('images/logo.png')"
        :alt="app_name() + 'logo'"
        class="logo"
      />
    </inertia-link>
    <h1 class="mb-1">Login</h1>
    <p class="mb-4 text-dim">
      Enter your email and password to access your account
    </p>
    <form action="" method="post" @submit.prevent="submit">
      <transition name="form-error" appear>
        <div
          class="alert alert-danger font-weight-bold"
          role="alert"
          v-if="errorMsg"
          v-text="errorMsg"
        ></div>
      </transition>
      <TextBox
        label="Email address"
        name="email"
        placeholder="Enter your email"
        v-model="form.email"
        :disabled="form.processing"
        :error="form.errors.email"
      />
      <TextBox
        label="Password"
        name="password"
        type="password"
        placeholder="Enter your password"
        v-model="form.password"
        :disabled="form.processing"
        :error="form.errors.password"
      />
      <div class="row mt-4">
        <div class="col">
          <div
            class="custom-control custom-control-alternative custom-checkbox"
          >
            <input
              class="custom-control-input"
              id="remember"
              type="checkbox"
              name="remember"
              v-model="form.remember"
            />
            <label class="custom-control-label" for="remember">
              <span class="text-muted">Remember me</span>
            </label>
          </div>
        </div>
        <div class="col text-lg-right d-none">
          <a href="" class="font-weight-bold">Forgot password?</a>
        </div>
      </div>
      <button
        class="mt-4 btn btn-primary w-100"
        type="submit"
        :disabled="form.processing"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="form.processing"
        ></div>
        <span v-else>Login</span>
      </button>
    </form>
    <div
      class="mt-4 d-flex justify-content-between flex-wrap align-items-center"
      style="gap: 0.5rem"
    >
      <p class="mb-0">
        Don't have an account?
        <inertia-link :href="$route('auth.register')" class="font-weight-bold"
          >Sign up</inertia-link
        >
      </p>
      <p class="mb-0">
        <inertia-link :href="$route('home.index')" class="font-weight-bold"
          >Back to home</inertia-link
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: this.$inertia.form({
        email: null,
        password: null,
        remember: true,
      }),
      alerts: {
        type: null,
        text: null,
      },
    };
  },
  methods: {
    submit() {
      this.form.post(this.$route("auth.login.submit"), {
        onSuccess: () => {
          this.form.password = null;
        },
        onError: () => {
          this.form.password = null;
        },
      });
    },
  },
  computed: {
    errorMsg() {
      return this.$page.props.flash.error_msg;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-wrapper {
  width: 600px;
  max-width: 100%;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background: var(--card-color);
  @media (max-width: 576px) {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  .logo {
    width: 168px;
    margin-bottom: 2rem;
  }
  h1 {
    font-size: 2rem;
  }
}
</style>