const BASE_URI = "/api/";

const prefixs = {
    admin: "admin",
    account: "admin/my-account",
    resetPassword: "forgot-password",
    extras: "extras",
    helpers: "helpers",
    reports: "reports",
};

const toUrl = (...path) => {
    path.unshift(BASE_URI);

    // remove trailing slash for all items in path and remove null items
    const pathArray = path.filter(item => item !== null || item != '' || typeof item != undefined)
        .map(item => item.endsWith("/") ? item.slice(0, -1) : item);

    // join path items with a slash and replace double slashes with a single slash
    return pathArray.join("/").replace(/\/\//g, "/");
};

const APIS = {
    csfrToken: toUrl('sanctum/csrf-cookie'),
    extras: {
        faqs: toUrl(prefixs.extras, 'faqs'),
    },
    helpers: {
        countries: toUrl(prefixs.helpers, 'countries'),
        statusSwitch: toUrl(prefixs.admin, 'status-switch'),
        getNumber: toUrl(prefixs.admin, 'get-number'),
    },
    auth: {
        login: toUrl(prefixs.admin, '/login'), // post
        logout: toUrl(prefixs.admin, '/logout'), // post
        sendOtp: toUrl(prefixs.resetPassword, '/send-otp'), // post
        resendOtp: toUrl(prefixs.resetPassword, '/resend-otp'), // post
        verifyOtp: toUrl(prefixs.resetPassword, '/verify-otp'), // post
        resetPassword: toUrl(prefixs.resetPassword, '/reset'), // post
    },
    users: {
        index: toUrl(prefixs.admin, 'users'),
        show: toUrl(prefixs.admin, 'users/{id}'),
        add: toUrl(prefixs.admin, 'users/create'),
        bulkUpload: toUrl(prefixs.admin, 'users/bulk-upload'),
        update: toUrl(prefixs.admin, 'users/update'),
        newUsers: toUrl(prefixs.admin, 'users/new-users'),
        accounts: toUrl(prefixs.admin, 'users/accounts'),
        envelopes: toUrl(prefixs.admin, 'users/envelopes'),
    },
    splashScreens: {
        index: toUrl(prefixs.admin, 'splash-screens'),
        show: toUrl(prefixs.admin, 'splash-screens/{id}'),
        add: toUrl(prefixs.admin, 'splash-screens/create'),
        update: toUrl(prefixs.admin, 'splash-screens/update'),
        delete: toUrl(prefixs.admin, 'splash-screens/{id}/delete'),
    },
    icons: {
        index: toUrl(prefixs.admin, 'icons'),
        show: toUrl(prefixs.admin, 'icons/{id}'),
        add: toUrl(prefixs.admin, 'icons/create'),
        update: toUrl(prefixs.admin, 'icons/update'),
        delete: toUrl(prefixs.admin, 'icons/{id}/delete'),
    },
    cohorts: {
        index: toUrl(prefixs.admin, 'cohorts'),
        show: toUrl(prefixs.admin, 'cohorts/{id}'),
        add: toUrl(prefixs.admin, 'cohorts/create'),
        update: toUrl(prefixs.admin, 'cohorts/update'),
    },
    faqs: {
        index: toUrl(prefixs.admin, 'faqs'),
        topics: toUrl(prefixs.admin, 'faqs/topics'),
        show: toUrl(prefixs.admin, 'faqs/{id}'),
        add: toUrl(prefixs.admin, 'faqs/create'),
        update: toUrl(prefixs.admin, 'faqs/update'),
        delete: toUrl(prefixs.admin, 'faqs/{id}/delete'),
    },
    myAccount: {
        profile: {
            base: toUrl(prefixs.account, 'profile'),
            update: toUrl(prefixs.account, 'profile/update'), // post
            updateInterests: toUrl(prefixs.account, 'profile/update-interests'), // post
            updateAvatar: toUrl(prefixs.account, 'profile/update-avatar'), // post
            changePassword: toUrl(prefixs.account, 'profile/change-password'), // post
            notification: {
                base: toUrl(prefixs.account, 'profile/notifications'),
                markRead: toUrl(prefixs.account, 'profile/notifications/mark-read'), // post
                delete: toUrl(prefixs.account, 'profile/notifications/delete'), // post
            },
        },
    },
    reports: {
        transactionThisWeek: toUrl(prefixs.admin, prefixs.reports, 'transaction-this-week'),
        incomeVsSpending: toUrl(prefixs.admin, prefixs.reports, 'income-vs-spending'),
        spendsByEnvelope: toUrl(prefixs.admin, prefixs.reports, 'spends-by-envelope'),
        spendsByDebits: toUrl(prefixs.admin, prefixs.reports, 'spends-by-debits'),
        incomeVsSpendingByAccount: toUrl(prefixs.admin, prefixs.reports, 'income-vs-spending-by-account'),
        incomeVsSpendingByCohort: toUrl(prefixs.admin, prefixs.reports, 'income-vs-spending-by-cohort'),
        spendsByMerchant: toUrl(prefixs.admin, prefixs.reports, 'spends-by-merchant'),
    },
    settings: {
        get: toUrl(prefixs.admin, 'settings/general'),
        update: toUrl(prefixs.admin, 'settings/general/update'), // post
    },
};

export default APIS;