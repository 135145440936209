<template>
  <Screen>
    <AppBar :title="title" :showBackButton="false" />
    <Breadcrumbs :title="title" />
    <iframe :src="url" frameborder="0" class="card card-body p-0"></iframe>
  </Screen>
</template>
<script>
export default {
  title: "Monitering apps",
  components: {},
  data() {
    return {
      urls: {
        applogs: "/admin/tp-apps/health/app-logs",
        health: "/admin/tp-apps/health/telescope",
        horizon: "/admin/tp-apps/health/horizon",
      },
    };
  },
  mounted() {
    this.PageTitle(this.title);
  },
  methods: {},
  computed: {
    title() {
      return this.$route.meta.type == "applogs" ? "App logs" : "App Health";
    },
    url() {
      return this.urls[this.$route.meta.type];
    },
  },
};
</script>
<style lang="scss" scoped>
iframe {
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  width: 100%;
}
</style>