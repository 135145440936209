<template>
  <FormLayout>
    <div>
      <form action="" method="post" @submit.prevent="submit">
        <h3>Upload image</h3>
        <hr />
        <div class="row">
          <div class="col-lg-6">
            <TextBox
              label="Image name (For SEO) [Optional]"
              name="slug"
              v-model="form.slug"
              :disabled="form.processing"
              :error="form.errors.slug"
            />
          </div>
          <div class="col-lg-6">
            <TextBox
              label="Alt text (For SEO) [Optional]"
              name="alt"
              v-model="form.alt"
              :disabled="form.processing"
              :error="form.errors.alt"
            />
          </div>
        </div>
        <FileInput
          name="image"
          v-model="form.image"
          :disabled="form.processing"
          :error="form.errors.image"
        />
        <div class="mt-4 text-center">
          <button
            class="btn btn-primary w-25"
            type="submit"
            :disabled="form.processing"
            @click.prevent="submit"
          >
            <div
              class="spinner-border spinner-border-sm"
              role="status"
              v-if="form.processing"
            ></div>
            <span v-else>Upload</span>
          </button>
        </div>
      </form>
      <hr />
      <h3>Images</h3>
      <hr />
      <div v-if="data.data.length">
        <div class="images">
          <div class="image-wrapper" v-for="item in data.data" :key="item.id">
            <div class="image">
              <img v-lazy="item.image_url" />
            </div>
            <div class="meta">
              <span>{{ item.slug }}</span>
              <span class="text-muted">Alt: {{ item.alt || "-" }}</span>
            </div>
            <button
              @click.prevent="deleteImage(item.id)"
              class="btn btn-danger btn-block btn-sm mt-2"
            >
              <small class="fa fa-trash mr-2"></small>Delete
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 class="text-dim text-center">No images uploaded yet</h4>
      </div>
    </div>
  </FormLayout>
</template>
<script>
import FileInput from "@/components/form/fileInput.vue";

export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
    deleteUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    FormLayout: () =>
      import(
        /* webpackChunkName: "admin-productform-layout" */ "./formLayout.vue"
      ),
    FileInput,
  },
  data() {
    return {
      form: this.$inertia.form({
        product_id: null,
        slug: "",
        alt: "",
        image: "",
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
    deleteImage(id) {
      let api = this.deleteUrl.replace(":id", id);
      this.form.delete(api, {
        data: {
          id,
        },
        options: {
          preserveScroll: true,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.images {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  .image-wrapper {
    img {
      height: 100%;
      max-height: 180px;
      object-fit: cover;
      width: 100%;
      background: var(--white);
      border-radius: var(--border-radius);
    }
    .meta {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      font-size: 90%;
    }
  }
}
</style>