import Vue from 'vue';
import Vuex from 'vuex';

import device from "./modules/device";
import auth from "./modules/auth";
import categories from './modules/categories';
import cart from './modules/cart';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        device,
        auth,
        categories,
        cart,
    },
});
