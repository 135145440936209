<template>
  <Container>
    <div class="row">
      <div class="col-lg-4 col-xl-3 m-auto">
        <MoreLinks />
      </div>
    </div>
  </Container>
</template>
<script>
import MoreLinks from "./moreLinks.vue";

export default {
  components: {
    MoreLinks,
  },
};
</script>