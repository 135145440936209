<template>
  <div class="item">
    <div class="preLoaderPlaceholder mb-0" style="height: 250px"></div>
    <div class="p-3">
      <div
        class="preLoaderPlaceholder mb-1 p-0 w-25"
        style="height: 18px"
      ></div>
      <div class="preLoaderPlaceholder mb-1 w-100 p-3"></div>
      <div class="preLoaderPlaceholder mb-2 w-50 p-3"></div>
      <div class="preLoaderPlaceholder mb-1 w-100 p-2"></div>
      <div class="preLoaderPlaceholder mb-3 w-75 p-2"></div>
      <div class="row">
        <div class="col-4">
          <div class="preLoaderPlaceholder mb-1 w-100 p-4"></div>
        </div>
        <div class="col"></div>
        <div class="col-5">
          <div class="preLoaderPlaceholder mb-1 w-100 p-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    count: {
      type: Number,
      default: 4,
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: block;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: var(--card-color);
  overflow: hidden;
}
</style>