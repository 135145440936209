<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.products.index'),
          title: 'Products',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <h3>Product Editor</h3>
            <TabsView :tabs="tabs" @selected="tabSelected">
              <transition name="fade" mode="out-in">
                <slot />
              </transition>
            </TabsView>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import TabsView from "@/components/tabsView.vue";
export default {
  components: {
    TabsView,
  },
  data() {
    return {
      tabs: [],
    };
  },
  mounted() {
    this.tabs = [
      {
        id: "Form",
        title: "Content",
        active: this.formTabActive,
        href: this.formTabUrl(),
      },
      {
        id: "ImagesForm",
        title: "Images",
        active: this.imageTabActive,
        disabled: this.imageTabDisabled,
        href: this.imageTabUrl(),
      },
    ];
  },
  methods: {
    tabSelected(tab) {
      if (tab.href == "#") return;
      // set active
      this.tabs.forEach((thistab) => {
        thistab.active = false;
        if (thistab.id == tab.id) {
          thistab.active = true;
        }
      });
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(tab.href);
    },
    formTabUrl() {
      if (this.$route().current() === "admin.products.create") {
        return this.$route("admin.products.create");
      }
      return this.$route("admin.products.edit", {
        product_id: this.$route().params.product_id || 0,
      });
    },
    imageTabUrl() {
      if (this.$route().current() === "admin.products.create") {
        return "#";
      }

      return this.$route("admin.products.images.index", {
        product_id: this.$route().params.product_id || 0,
      });
    },
  },
  computed: {
    title() {
      return this.$page.props.title;
    },
    formTabActive() {
      return (
        this.$route().current() === "admin.products.create" ||
        this.$route().current() === "admin.products.edit"
      );
    },
    imageTabDisabled() {
      return this.$route().current() === "admin.products.create";
    },
    imageTabActive() {
      return this.$route().current() === "admin.products.images.index";
    },
  },
};
</script>