const state = {
    cart: [],
    cartCount: 0,
};

const getters = {
    cart: state => state.cart,
    cartCount: state => state.cartCount,
};

const mutations = {
    setCart: (state, payload) => (state.cart = payload),
    setCartCount: (state, payload) => (state.cartCount = payload),
};

const actions = {
    setCartData({ commit }, payload) {
        commit('setCart', payload);

        if (payload.length > 0) {
            let count = 0;
            payload.forEach(item => {
                count += item.quantity;
            });

            commit('setCartCount', count);
        } else {
            commit('setCartCount', 0);
        }
    },

    async fetchCart({ dispatch }) {
        const response = await axios.get(route('home.api.cart'));
        return dispatch('setCartData', response.data.data);
    },

    async addToCart({ dispatch }, payload) {
        const response = await axios.post(route('home.api.cart.store'), {
            product_id: payload,
        });
        return dispatch('setCartData', response.data.data);
    },

    async removeFromCart({ dispatch }, payload) {
        const response = await axios.delete(route('home.api.cart.remove', {
            product_id: payload,
        }));
        return dispatch('setCartData', response.data.data);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};