<template>
  <Container>
    <SectionHeader title="Contact Us" />
    <div class="row">
      <div class="col-12" v-if="mapUrl">
        <iframe
          :src="mapUrl"
          frameborder="0"
          class="info-box p-0"
          height="450"
          width="100%"
          style="border: 0"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-lg-5 col-xl-4">
        <div class="info-box contact-box">
          <i class="fa fa-phone-alt"></i>
          <div class="content">
            <h3>Call us</h3>
            <p>
              <a :href="`tel:+${settings.phone_primary}`" title="Contact us">
                {{ settings.phone_primary }}
              </a>
              <template v-if="settings.phone_secondary">
                <span>, </span>
                <a
                  :href="`tel:+${settings.phone_secondary}`"
                  title="Contact us"
                >
                  {{ settings.phone_secondary }}
                </a>
              </template>
            </p>
          </div>
        </div>
        <div class="info-box contact-box">
          <i class="fa fa-inbox"></i>
          <div class="content">
            <h3>Write to us</h3>

            <p>
              <a :href="`mailto:${settings.email_primary}`" title="Email us">
                {{ settings.email_primary }}
              </a>
              <span v-if="settings.email_secondary">, </span>
              <a
                :href="`mailto:${settings.email_secondary}`"
                title="Email us"
                class="ml-1"
              >
                {{ settings.email_secondary }}
              </a>
            </p>
          </div>
        </div>
        <div class="info-box contact-box">
          <i class="fa fa-location-arrow"></i>
          <div class="content">
            <h3>Visit us</h3>
            <p>
              <b>{{ settings.address_primary }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-8">
        <form
          method="post"
          class="info-box"
          role="form"
          @submit.prevent="submit"
        >
          <div class="text-center mb-4">
            <h3>Let&#x2019;s Connect</h3>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <TextBox
                label="Name"
                name="name"
                placeholder="Enter name"
                v-model="form.name"
                :disabled="form.processing"
                :error="form.errors.name"
              />
            </div>
            <div class="col-lg-6">
              <TextBox
                label="Email"
                name="email"
                placeholder="Enter email"
                v-model="form.email"
                :disabled="form.processing"
                :error="form.errors.email"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <TextBox
                label="Mobile number"
                name="mobile"
                placeholder="Enter mobile"
                v-model="form.mobile"
                :disabled="form.processing"
                :error="form.errors.mobile"
              />
            </div>
            <div class="col-lg-6">
              <TextBox
                label="Subject"
                name="subject"
                placeholder="Enter subject"
                v-model="form.subject"
                :disabled="form.processing"
                :error="form.errors.subject"
              />
            </div>
          </div>
          <TextBox
            type="textarea"
            label="Message"
            name="message"
            placeholder="Enter message"
            v-model="form.message"
            :disabled="form.processing"
            :error="form.errors.message"
          />
          <div class="mt-4 text-center">
            <button
              class="btn btn-primary w-50"
              type="submit"
              :disabled="form.processing"
            >
              <div
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="form.processing"
              ></div>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <SocialLinks />
  </Container>
</template>
<script>
export default {
  components: {
    SocialLinks: () =>
      import(
        /* webpackChunkName: "home-shared-social-links" */ "./shared/socialLinks"
      ),
  },
  props: {
    mapUrl: null,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.$route("home.contact.submit"), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset();
          this.form.clearErrors();
        },
      });
    },
  },
  computed: {
    settings() {
      return this.$page.props.config.settings;
    },
  },
};
</script>
<style lang="scss" scoped>
.info-box {
  background: var(--card-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  &.contact-box {
    display: flex;
    gap: 0.8rem;
    padding: 1.8rem 2rem;
    i {
      font-size: 1.3rem;
      color: var(--primary-dark);
      border-radius: 50%;
      background: rgba(var(--primary-rgb), 0.1);
      height: 3.4rem;
      width: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h3 {
      margin-bottom: 0.8rem;
    }
    a {
      font-weight: bold;
    }
    .content {
      flex: 1;
    }
  }
}
</style>