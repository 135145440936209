<template>
  <Screen>
    <AppBar title="Contact us enquiries" :showBackButton="false" />
    <Breadcrumbs title="Contact us enquiries" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <h4 class="mb-0">Filter</h4>
          </div>
          <div class="col-auto">
            <div class="form-group mb-0">
              <select
                class="form-control form-control-sm"
                @change="displayModeChange"
                v-model="displayMode"
              >
                <option value="default">Default view</option>
                <option value="archived">Display archived</option>
                <option value="all">Display all</option>
              </select>
            </div>
          </div>
        </div>
        <template v-if="data.data.length > 0">
          <div class="table-responsive mt-4">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Created</th>
                  <th>Date time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.data" :key="item.id">
                  <td>
                    {{
                      i + 1 + (data.meta.current_page - 1) * data.meta.per_page
                    }}
                  </td>
                  <th>{{ item.name }}</th>
                  <td>{{ item.email }}</td>
                  <td>{{ item.mobile }}</td>
                  <td>{{ item.subject }}</td>
                  <td>{{ item.message }}</td>
                  <th>{{ item.time_ago }}</th>
                  <td>{{ item.created_at_human }}</td>
                  <td>
                    <div
                      style="display: flex; flex-direction: column; gap: 0.5rem"
                    >
                      <inertia-link
                        v-if="item.archived"
                        class="btn btn-sm btn-warning"
                        :href="
                          $route('admin.contactus.archive', {
                            id: item.id,
                            archived: 0,
                          })
                        "
                        >Restore</inertia-link
                      >
                      <inertia-link
                        v-else
                        class="btn btn-sm btn-danger-outline"
                        :href="
                          $route('admin.contactus.archive', {
                            id: item.id,
                            archived: 1,
                          })
                        "
                        >Archive</inertia-link
                      >
                      <inertia-link
                        as="button"
                        method="delete"
                        class="btn btn-sm btn-danger"
                        :href="
                          $route('admin.contactus.delete', {
                            id: item.id,
                          })
                        "
                        >Delete</inertia-link
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination :rawData="data.meta" :onClick="pageChange" />
        </template>
        <NoData title="No enquiries added yet" :button="false" v-else />
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    displayingData: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      displayMode: "default",
    };
  },
  watch: {
    displayingData: {
      handler(newValue) {
        this.displayMode = newValue;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    pageChange(page) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          page: page,
          search: this.searchInput,
        })
      );
    },
    displayModeChange() {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          display: this.displayMode,
          page: 1,
        })
      );
    },
  },
  computed: {},
};
</script>