<template>
  <div class="wrapper">
    <div
      class="
        heading
        mb-4
        pb-1
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <h4 class="mb-0">Shop by category</h4>
    </div>
    <div class="data">
      <Item
        v-for="category in categories"
        :key="category.id"
        :category="category"
        class="item"
      />
    </div>
    <div class="text-center">
      <inertia-link
        :href="$route('home.categories')"
        class="btn btn-outline w-100"
        >See all<small class="fa fa-arrow-right ml-2"></small>
      </inertia-link>
    </div>
  </div>
</template>
<script>
import Item from "./item.vue";
export default {
  components: { Item },
  computed: {
    categories() {
      return this.$store.getters.featuredCategories;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 1rem;
  background: var(--card-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  height: 100%;
  width: 100%;
  max-height: 280px;
  overflow: auto;

  @media (min-width: 1310px) {
    max-height: unset;
  }
}
</style>