<template>
  <App>
    <div class="layout">
      <Sidebar :links="sideNavRoutes" />
      <main>
        <transition
          :name="transitionName"
          mode="out-in"
          :appear="false"
          @before-enter="beforeTransition"
          @before-leave="beforeTransition"
          @after-leave="afterTransition"
          @after-enter="afterTransition"
        >
          <slot />
        </transition>
      </main>
    </div>
  </App>
</template>
<script>
const pageTransitionName = "smooth-slide";

import App from "../../App.vue";

import {
  PhHouse,
  PhCirclesFour,
  PhCirclesThreePlus,
  PhCards,
  PhStar,
  PhUsers,
  PhHeadset,
  PhPackage,
  PhTruck,
  PhGear,
  PhMoney,
} from "phosphor-vue";

export default {
  name: "Layout",
  components: {
    App,
    Sidebar: () =>
      import(/* webpackChunkName: "layouts-admin-sidebar" */ "./sidebar"),
  },
  watch: {
    "$page.url": {
      immediate: true,
      handler(newData, oldData) {
        // don't do transition for the first page load
        if (!oldData) {
          this.transitionName = null;
          return;
        }

        if (this.transitionOverRidden) {
          return;
        }

        if (newData) {
          this.transitionName = pageTransitionName;
        }

        // clear transition name after transition end,
        // to prevent unwanted animation when modal is shown
        setTimeout(() => {
          this.transitionName = null;
        }, 1000);
      },
    },
  },
  data() {
    return {
      transitionName: null,
      transitionOverRidden: false,
    };
  },
  mounted() {
    this.$root.$on("hold-transition", (name = null) => {
      this.transitionOverRidden = true;
      this.transitionName = name;
      setTimeout(() => {
        this.transitionOverRidden = false;
        this.transitionName = null;
      }, 1000);
    });
  },
  methods: {
    beforeTransition() {
      document.body.style.overflow = "hidden";
    },
    afterTransition() {
      document.body.style.overflow = null;
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    counts() {
      return this.$page.props.new_counts || {};
    },
    sideNavRoutes() {
      return [
        {
          title: "Dashboard",
          icon: PhHouse,
          to: "admin.index",
          deepActive: false,
        },
        {
          title: "Contact us",
          icon: PhHeadset,
          to: "admin.contactus",
          deepActive: false,
          badge: {
            value: this.counts.contact_us || 0,
            valueSuffix: " new",
            color: "danger",
          },
        },
        {
          title: "Orders",
          icon: PhTruck,
          to: "admin.orders.index",
          deepActive: false,
          badge: {
            value: this.counts.orders || 0,
            valueSuffix: " new",
            color: "danger",
          },
        },
        {
          title: "Users",
          icon: PhUsers,
          to: "admin.users.index",
          deepActive: true,
        },
        {
          title: "Products",
          icon: PhPackage,
          to: "admin.products.index",
          deepActive: true,
        },
        {
          title: "Categories",
          icon: PhCirclesFour,
          to: "#",
          deepActive: true,
          deepActiveRoute: "admin.categories.*",
          children: [
            {
              title: "Categories",
              icon: PhCirclesThreePlus,
              to: "admin.categories.categories.index",
              deepActive: true,
            },
            {
              title: "Sub categories",
              icon: PhCirclesThreePlus,
              to: "admin.categories.subcategories.index",
              deepActive: true,
            },
          ],
        },
        {
          title: "Sliders",
          icon: PhCards,
          to: "admin.sliders.index",
          deepActive: true,
        },
        {
          title: "Reviews",
          icon: PhStar,
          to: "admin.reviews.index",
          deepActive: true,
        },
        {
          title: "Currencies",
          icon: PhMoney,
          to: "admin.currencies.index",
          deepActive: true,
          badge: {
            value: this.counts.currency || 0,
            valueSuffix: " errors",
            color: "danger",
          },
        },
        {
          title: "Settings",
          icon: PhGear,
          to: "admin.settings.index",
          deepActive: false,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  margin-left: 320px;
  @media (max-width: 1055px) {
    margin-left: 0;
  }
}

// smooth slide

.smooth-slide-enter-active,
.smooth-slide-leave-active {
  z-index: -10;
  position: relative;
  transition: all 0.25s cubic-bezier(0.7, 0, 0.3, 1);
}

.smooth-slide-enter-from,
.smooth-slide-leave-to {
  opacity: 0;
  transform: translateY(5%);
  @media (max-width: 1055px) {
    transform: translateX(-2%);
  }
}

.smooth-slide-active,
.smooth-slide-active {
  z-index: -1;
  position: relative;
  transition: all 0.25s cubic-bezier(0.7, 0, 0.3, 1);
  overflow: hidden;
}

.smooth-slide-enter,
.smooth-slide-leave-active {
  opacity: 0;
  transform: translateY(5%);
  @media (max-width: 1055px) {
    transform: translateX(-2%);
  }
}
</style>