<template>
  <Container>
    <div>
      <SectionHeader title="My Cart" />
      <div class="row" v-if="cart.length">
        <div class="col-lg-7">
          <div class="alert alert-warning" v-if="cart.length <= 0">
            <p class="mb-0">
              <i class="fa fa-exclamation-triangle"></i>
              <b>You have no items in your cart.</b>
            </p>
          </div>
          <transition-group name="remove-item" tag="div" class="list">
            <Item v-for="item in cart" :key="item.id" :data="item" />
          </transition-group>
        </div>
        <div class="col-lg-5">
          <div class="form-wrapper">
            <form action="" @submit.prevent="submit">
              <h4 class="title bottom-divider">Overview</h4>
              <h3 class="mb-2 price">
                <small>Quantity</small>
                <span class="text-right" v-text="cartMeta.count"></span>
              </h3>
              <h3 class="mb-3 bottom-divider price">
                <small>Total</small>
                <span
                  class="text-right"
                  v-text="currencyFormat(cartMeta.total)"
                ></span>
              </h3>
              <TextBox
                type="textarea"
                name="address_line_1"
                v-model="form.address_line_1"
                :disabled="form.processing"
                :error="form.errors.address_line_1"
                :rows="2"
              />
              <TextBox
                type="textarea"
                name="address_line_2"
                v-model="form.address_line_2"
                :disabled="form.processing"
                :error="form.errors.address_line_2"
                :rows="1"
              />
              <TextBox
                name="landmark"
                v-model="form.landmark"
                :disabled="form.processing"
                :error="form.errors.landmark"
              />
              <div class="row">
                <div class="col-xl-4 col-lg-6">
                  <TextBox
                    name="city"
                    v-model="form.city"
                    :disabled="form.processing"
                    :error="form.errors.city"
                  />
                </div>
                <div class="col-xl-4 col-lg-6">
                  <TextBox
                    name="state"
                    v-model="form.state"
                    :disabled="form.processing"
                    :error="form.errors.state"
                  />
                </div>
                <div class="col-xl-4 col-lg-12">
                  <TextBox
                    name="zip"
                    v-model="form.zip"
                    :disabled="form.processing"
                    :error="form.errors.zip"
                  />
                </div>
              </div>

              <div class="mt-3 text-center">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  :disabled="form.processing || cartMeta.count <= 0"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Buy now</span>
                </button>
              </div>
            </form>
            <p class="mt-3 text-center">OR</p>
            <div class="contact-links">
              <a class="contact-link" :href="`tel:+${settings.phone_primary}`">
                <div class="icon">
                  <PhPhone weight="duotone" />
                </div>
                <div class="text">{{ settings.phone_primary }}</div>
              </a>
              <a
                class="contact-link"
                :href="`mailto:${settings.email_primary}`"
              >
                <div class="icon">
                  <PhPaperPlaneTilt weight="duotone" />
                </div>
                <div class="text">{{ settings.email_primary }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <NoData title="You cart is empty!" v-else>
        <inertia-link
          :href="$route('home.products.index')"
          class="btn btn-primary"
          >Continue shopping&nbsp;<small class="fa fa-arrow-right"></small
        ></inertia-link>
      </NoData>
    </div>
  </Container>
</template>
<script>
import Item from "./item.vue";
import { PhPhone, PhPaperPlaneTilt } from "phosphor-vue";

export default {
  components: {
    Item,
    PhPhone,
    PhPaperPlaneTilt,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        city: "",
        state: "",
        zip: "",
      }),
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        address_line_1: null,
        address_line_2: null,
        landmark: null,
        city: null,
        state: null,
        zip: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
      }),
    };
  },
  mounted() {
    this.$store.dispatch("fetchCart");

    this.form.address_line_1 = this.formData.address_line_1;
    this.form.address_line_2 = this.formData.address_line_2;
    this.form.landmark = this.formData.landmark;
    this.form.city = this.formData.city;
    this.form.state = this.formData.state;
    this.form.zip = this.formData.zip;

    this.form.utm_source = this.$route().params.utm_source || null;
    this.form.utm_medium = this.$route().params.utm_medium || null;
    this.form.utm_campaign = this.$route().params.utm_campaign || null;
  },
  methods: {
    submit() {
      this.form.post(this.$route("home.cart.checkout"), {
        onSuccess: () => {
          this.form.reset();
          this.form.clearErrors();
          this.$store.dispatch("fetchCart").then(() => {
            this.$root.$emit("cart:updated");
          });
        },
      });
    },
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    cartMeta() {
      let count = 0;
      let total = 0;
      this.cart.forEach((item) => {
        if (item.product.in_stock) {
          count += item.quantity;
          total += item.product.selling_price * item.quantity;
        }
      });
      return {
        count,
        total,
      };
    },
    settings() {
      return this.$page.props.config.settings;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-wrapper {
  // position: sticky;
  // top: 110px;
  form {
    background: var(--card-color);
    padding: 1rem 0.8rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    .bottom-divider {
      border-bottom: 2px dashed var(--lighter);
      padding-bottom: 0.5rem;
    }
    .price {
      display: flex;
      justify-content: space-between;
      gap: 0.8rem;
      align-items: center;
      span {
        flex: 1;
      }
    }
  }
  .contact-links {
    padding: 0.8rem 0;
    background: var(--card-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .contact-link {
      flex: 1;
      color: var(--dark);
      transition: var(--transition);
      padding: 0 0.8rem;
      &:hover {
        color: var(--primary);
      }
      &:first-child {
        border-right: 2px solid var(--lighter);
      }
      .icon {
        margin-bottom: 0.4rem;
        svg {
          font-size: 2rem;
        }
      }
    }
  }
}
</style>