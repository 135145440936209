<template>
  <Container>
    <SectionHeader :title="title" />
    <div class="wrapper">
      <inertia-link
        v-for="category in subCategories.data"
        :key="category.id"
        :href="$route('home.products.index', { sub_category: category.slug })"
        class="item"
      >
        <div class="img-wrapper">
          <img v-lazy="category.image_url" :alt="category.name" />
        </div>
        <h3 class="mb-1 mt-3" v-text="category.name"></h3>
        <p class="mb-0 text-muted" style="font-size: 90%">
          <b v-text="category.products_count"></b> Products
        </p>
      </inertia-link>
    </div>
  </Container>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Categories",
    },
    subCategories: {
      type: Object,
      default: () => ({
        data: [],
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  .item {
    text-align: center;
    .img-wrapper {
      width: 100%;
      height: 250px;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h3 {
      color: var(--dark);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      h3 {
        color: var(--primary);
      }
    }
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
    .item {
      .img-wrapper {
        height: 200px;
      }
      h3 {
        font-size: 1.4rem;
      }
    }
  }
}
</style>