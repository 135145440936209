<template>
  <Screen>
    <AppBar :showBackButton="false" title="My profile" />
    <Breadcrumbs title="My profile" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <TabsView :tabs="tabs" @selected="(tab) => (selectedTab = tab.id)">
              <transition name="fade" mode="out-in">
                <conponent v-if="selectedTab" :is="selectedTab"></conponent>
              </transition>
            </TabsView>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import TabsView from "@/components/tabsView.vue";
export default {
  title: "My profile",
  components: {
    TabsView,
    Profile: () =>
      import(/* webpackChunkName: "profile-edit-profile" */ "./profile.vue"),
    ChangePassword: () =>
      import(
        /* webpackChunkName: "profile-edit-password" */ "./changePassword"
      ),
  },
  data() {
    return {
      tabs: [
        {
          id: "Profile",
          title: "Profile",
          active: true,
        },
        {
          id: "ChangePassword",
          title: "Change password",
          active: false,
        },
      ],
      selectedTab: "Profile",
    };
  },
};
</script>