<template>
  <form action="" method="post" @submit.prevent="submit">
    <TextBox
      label="Name"
      name="name"
      placeholder="Enter your name"
      v-model="form.name"
      :disabled="form.processing"
      :error="form.errors.name"
    />
    <TextBox
      label="Email"
      name="email"
      placeholder="Enter your email"
      v-model="form.email"
      :disabled="form.processing"
      :error="form.errors.email"
    />
    <div class="mt-4 text-center">
      <button
        class="btn btn-primary w-75"
        type="submit"
        :disabled="form.processing"
        @click.prevent="submit"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="form.processing"
        ></div>
        <span v-else>Save</span>
      </button>
    </div>
  </form>
</template>
<script>
import APIS from "@/helpers/apis";

export default {
  components: {},
  data() {
    return {
      type: "update",
      form: this.$form({
        name: null,
        email: null,
        avatar: null,
      }),
    };
  },
  mounted() {
    this.PageTitle("My profile");

    this.form.fill(this.user);
  },
  methods: {
    submit() {
      this.form.post(APIS.myAccount.profile.update, {
        onSuccess: (response) => {
          if (response.status && response.success_msg) {
            this.$store.dispatch("auth/updateUser", response.user);
          }
        },
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
};
</script>