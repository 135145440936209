<template>
  <Screen>
    <AppBar title="Currencies" :showBackButton="false">
      <inertia-link
        :href="$route('admin.currencies.create')"
        class="btn btn-sm btn-primary"
      >
        <i class="fa fa-plus"></i>
        <span class="d-none d-app-inline">Add new</span>
      </inertia-link>
    </AppBar>
    <Breadcrumbs title="Currencies" />
    <div class="card">
      <div class="card-body">
        <SearchBar
          @onSubmit="searchSubmit"
          @onClear="searchCleared"
          v-model="searchInput"
        />
        <h5 class="mb-3">
          Default currency:
          <b>{{ defaultCurrency.code }} - {{ defaultCurrency.symbol }}</b>
        </h5>
        <template v-if="data.data.length > 0">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Currency code</th>
                  <th>Display name</th>
                  <th>Exchange rate</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.data" :key="item.id">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <th>
                    {{ item.code }}
                    <span v-if="item.updated_diff" class="text-danger">
                      (Action required)
                    </span>
                  </th>
                  <td>{{ item.display_name }}</td>
                  <td>{{ item.exchange_rate }}</td>
                  <td>{{ item.created_at_human }}</td>
                  <td>
                    {{ item.updated_at_human }}
                    <div v-if="item.updated_diff">
                      <small class="badge-danger badge-pill">
                        <b>{{ item.updated_diff }}</b> days ago
                      </small>
                    </div>
                  </td>
                  <td>
                    <inertia-link
                      class="btn btn-sm btn-light"
                      :href="
                        $route('admin.currencies.edit', {
                          id: item.id,
                        })
                      "
                      >Edit</inertia-link
                    >
                    <inertia-link
                      as="button"
                      method="delete"
                      class="btn btn-sm btn-danger"
                      :href="
                        $route('admin.currencies.delete', {
                          id: item.id,
                        })
                      "
                      >Delete</inertia-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <NoData title="No currencies added yet" v-else>
          <inertia-link
            :href="$route('admin.currencies.create')"
            class="btn btn-primary"
            ><i class="fa fa-plus"></i> Add new</inertia-link
          >
        </NoData>
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    defaultCurrency: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: String,
      default: "",
    },
  },
  watch: {
    search: {
      immediate: true,
      handler(value) {
        this.searchInput = value;
      },
    },
  },
  data() {
    return {
      searchInput: "",
    };
  },
  mounted() {},
  methods: {
    searchSubmit(search) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: search,
        })
      );
    },
    searchCleared() {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: "",
        })
      );
    },
  },
  computed: {},
};
</script>
