<template>
  <div>
    <Container>
      <SectionHeader title="About us" />
      <h4 class="mb-4 text-dim">
        Mediniq Healthcare Our medical service engineers operate nationally
        carrying out annual servicing and calibration procedures for your new
        and used medical equipment.
      </h4>
      <div class="row mb-5">
        <div class="col-lg-6">
          <p class="mb-0" style="font-size: 1.1rem">
            Mediniq Healthcare is dedicated in providing you advanced
            respiratory care from world's trusted brand. Mediniq provide,
            maintain, and support a wide range of medical equipment, with
            experience gained through serving the MoD, private hospitals, NHS,
            CCG's and General and Other Practices.
          </p>
        </div>
        <div class="col-lg-6">
          <p class="mb-0" style="font-size: 1.1rem">
            We offer a single all-encompassing repair, and asset management
            solution, reducing both the cost and the need for multiple
            contractors for our customers.From individual medical practices to
            entire private hospital groups, we become your technical support
            experts, planned preventative maintenance, covering fault repairs,
            procurement consultancy and second hand equipment resale.
          </p>
        </div>
      </div>
      <h4 class="mb-3">
        Mediniq Healthcare is dedicated in providing you advanced respiratory
        care from world's trusted brand
      </h4>
      <p>
        Our medical service engineers operate nationally carrying out annual
        servicing and calibration procedures for your new and used medical
        equipment.Our Products are form the world class equipment providers like
        Devilbiss, Inogen, Joyceone, Airforce One, Prima Vent.
      </p>
      <p>
        At Mediniq Healthcare, We take care of sales, service and rentals of
        portable and advanced medical equipments such as Portable Oxygen
        Concentrator, CPAP & BI PAP Masks, Oximeters etc...
      </p>
    </Container>
    <SocialLinks />
    <Reviews />
    <Partners />
  </div>
</template>
<script>
export default {
  components: {
    Partners: () =>
      import(
        /* webpackChunkName: "home-shared-partners" */ "./shared/partners"
      ),
    Reviews: () =>
      import(/* webpackChunkName: "home-shared-reviews" */ "./shared/reviews"),
    SocialLinks: () =>
      import(
        /* webpackChunkName: "home-shared-social-links" */ "./shared/socialLinks"
      ),
  },
};
</script>