<template>
  <Screen>
    <AppBar title="Categories" :showBackButton="false">
      <inertia-link
        :href="$route('admin.categories.categories.create')"
        class="btn btn-sm btn-primary"
      >
        <i class="fa fa-plus"></i>
        <span class="d-none d-app-inline">Add new</span>
      </inertia-link>
    </AppBar>
    <Breadcrumbs title="Categories" />
    <div class="card">
      <div class="card-body">
        <SearchBar
          @onSubmit="searchSubmit"
          @onClear="searchCleared"
          v-model="searchInput"
        />
        <template v-if="data.data.length > 0">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Icon</th>
                  <th>Image</th>
                  <th>Sub categories</th>
                  <th>Products</th>
                  <th>Status</th>
                  <th>Featured Status</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.data" :key="item.id">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <th>{{ item.name }}</th>
                  <td>
                    <img v-lazy="item.icon_url" class="img-rounded-sm" />
                  </td>
                  <td>
                    <img v-lazy="item.image_url" class="img-rounded-sm" />
                  </td>
                  <th>
                    <inertia-link
                      :href="
                        $route('admin.categories.subcategories.create', {
                          category: item.id,
                        })
                      "
                    >
                      {{ item.sub_categories_count }}&nbsp;
                      <button class="btn btn-rounded-sm btn-primary">
                        <i class="fa fa-plus"></i>
                      </button>
                    </inertia-link>
                  </th>
                  <th>{{ item.products_count }}</th>
                  <th>
                    <StatusSwitch
                      :dataid="item.id"
                      :status="item.is_active"
                      action="categoriesStatus"
                    />
                  </th>
                  <th>
                    <StatusSwitch
                      labelText="Yes/No"
                      :dataid="item.id"
                      :status="item.is_featured"
                      action="categoriesFeaturedStatus"
                    />
                  </th>
                  <td>{{ item.created_at_human }}</td>
                  <td>{{ item.updated_at_human }}</td>
                  <td>
                    <inertia-link
                      class="btn btn-sm btn-light"
                      :href="
                        $route('admin.categories.categories.edit', {
                          id: item.id,
                        })
                      "
                      >Edit</inertia-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <NoData title="No categories added yet" v-else>
          <inertia-link
            :href="$route('admin.categories.categories.create')"
            class="btn btn-primary"
            ><i class="fa fa-plus"></i> Add new</inertia-link
          >
        </NoData>
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
  },
  watch: {
    search: {
      immediate: true,
      handler(value) {
        this.searchInput = value;
      },
    },
  },
  data() {
    return {
      searchInput: "",
    };
  },
  mounted() {},
  methods: {
    searchSubmit(search) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: search,
        })
      );
    },
    searchCleared() {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: "",
        })
      );
    },
  },
  computed: {},
};
</script>
<style scoped>
.btn.btn-rounded-sm {
  padding: 0;
  font-size: 0.74rem !important;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  display: inline-grid;
  place-content: center;
}
</style>
