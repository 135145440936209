<template>
  <div>
    <section class="pb-lg-3 pb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-11 col-lg-12 m-auto">
            <div class="row">
              <div class="col d-none d-lg-flex">
                <HeroCategories />
              </div>
              <div class="col-xl-10 col-lg-9">
                <SearchBar
                  v-model="search"
                  @onSubmit="searchSubmit"
                  placeholder="Search in all medical equipments"
                  class="search-bar"
                />
                <Slider sliderType="hero" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <DynamicProducts
      title="Featured products"
      :api="
        $route('home.api.products', {
          type: 'featured',
        })
      "
    />
    <Categories />
    <div class="container">
      <Slider sliderType="offers" />
    </div>
    <template v-if="categories.length">
      <DynamicProducts
        v-for="category in categories"
        :key="category.id"
        :api="
          $route('home.api.products', {
            category_id: category.id,
          })
        "
        :title="category.name"
      />
    </template>
    <Reviews />
    <Partners />
  </div>
</template>
<script>
export default {
  components: {
    DynamicProducts: () =>
      import(
        /* webpackChunkName: "products-dynamiclist" */ "../products/dynamicData.vue"
      ),
    HeroCategories: () =>
      import(/* webpackChunkName: "home-hero-categories" */ "./heroCategories"),
    Categories: () =>
      import(/* webpackChunkName: "home-categories" */ "./categories"),
    Slider: () =>
      import(/* webpackChunkName: "home-shared-slider" */ "../shared/slider"),
    // Services: () =>
    //   import(
    //     /* webpackChunkName: "home-shared-services" */ "../shared/services"
    //   ),
    Partners: () =>
      import(
        /* webpackChunkName: "home-shared-partners" */ "../shared/partners"
      ),
    Reviews: () =>
      import(/* webpackChunkName: "home-shared-reviews" */ "../shared/reviews"),
  },
  data() {
    return {
      search: "",
    };
  },
  mounted() {},
  methods: {
    searchSubmit() {
      this.$inertia.visit(
        this.$route("home.products.index", {
          search: this.search,
        })
      );
    },
  },
  computed: {
    categories() {
      return this.$store.getters.featuredCategories;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  margin-bottom: 0.8rem !important;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  @media (max-width: 992px) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1rem !important;
  }
}
</style>