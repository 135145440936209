<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.reviews.index'),
          title: 'Reviews',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <form
              action=""
              method="post"
              @submit.prevent="submit"
              v-if="formSet"
            >
              <div class="form-group">
                <label for="rating">Rating</label>
                <AdvanceSelect
                  class="advance-dpdown"
                  :options="[1, 2, 3, 4, 5]"
                  id="rating"
                  label="title"
                  placeholder="Select rating"
                  :disabled="form.processing"
                  :class="{ 'is-invalid': form.errors.rating }"
                  v-model="form.rating"
                >
                </AdvanceSelect>
                <transition name="form-error">
                  <div
                    class="error"
                    v-if="form.errors.rating"
                    v-text="form.errors.rating"
                  ></div>
                </transition>
              </div>
              <TextBox
                name="name"
                v-model="form.name"
                :disabled="form.processing"
                :error="form.errors.name"
              />
              <TextBox
                type="textarea"
                name="message"
                v-model="form.message"
                :disabled="form.processing"
                :error="form.errors.message"
              />
              <FileInput
                label="Image *"
                name="image"
                v-model="form.image"
                :fileUrl="form.image_url"
                :disabled="form.processing"
                :error="form.errors.image"
              />
              <StatusRadio
                v-model="form.is_active"
                :disabled="form.processing"
                :error="form.errors.is_active"
              />
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import FileInput from "@/components/form/fileInput.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    AdvanceSelect: vSelect,
    FileInput,
  },
  props: {
    slider_types: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      this.form.image = null;
      this.form.image_mobile = null;
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
  computed: {},
};
</script>
