const state = {
    categories: [],
    featuredCategories: [],
};

const getters = {
    categories: state => state.categories,
    featuredCategories: state => state.featuredCategories,
};

const mutations = {
    setCategories: (state, cats) => (state.categories = cats),
    setFeaturedCategories: (state, cats) => (state.featuredCategories = cats),
};

const actions = {
    async fetchCategories({ commit }) {
        const response = await axios.get(route('home.api.categories'));
        commit('setCategories', response.data.data);

        if (response.data.data.length > 0) {
            // set featured categories

            let featured = [];
            featured = response.data.data.filter(category => category.is_featured);

            if (featured.length <= 0) {
                // get first 4 categories
                featured = response.data.data.slice(0, 4);
            }

            commit('setFeaturedCategories', featured);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};