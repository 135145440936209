/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia'
import { InertiaProgress } from '@inertiajs/progress';
import VueLazyload from 'vue-lazyload';
import './mixins/helperMixins';
import store from './store';

Vue.use(VueLazyload, {
    loading: '/images/loading.png',
    attempt: 1,
    observer: true,
});

Vue.config.productionTip = false;

Vue.prototype.$sideNavOpen = true;
Vue.prototype.$route = route;

InertiaProgress.init({
    // The color of the progress bar.
    color: "var(--primary)",

    delay: 250,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
});

Inertia.on('success', (event) => {
    // scroll to top
    window.scrollTo(0, 0);
});

// layouts
import HomeLayout from "./layouts/home/index";
import AdminLayout from "./layouts/admin/index";
import AuthLayout from "./layouts/auth";

// global components
Vue.component("AppBar", () => import("./components/appBar"));
Vue.component("Screen", () => import("./components/screen"));
Vue.component("Container", () => import("./components/container.vue"));
Vue.component("Modal", () => import("./components/modal"));
Vue.component("NoData", () => import("./components/noData"));
Vue.component("Breadcrumbs", () => import("./components/breadcrumbs"));
Vue.component("TextBox", () => import("./components/form/textbox"));
Vue.component("StatusRadio", () => import("./components/form/statusRadio"));
Vue.component("StatusSwitch", () => import("./components/statusSwitch"));
Vue.component("InfiniteScroll", () => import("./components/infiniteScroll.vue"));
Vue.component("SearchBar", () => import("./components/searchBar"));
Vue.component("Pagination", () => import("./components/Pagination/index"));
Vue.component("inertia-link", Link);
Vue.component("SectionHeader", () => import("./components/sectionHeader.vue"));

store.dispatch("setTheme");

createInertiaApp({
    resolve: name => {
        const page = require(`./views/${name}`).default;
        if (page.layout === undefined) {
            page.layout = HomeLayout;
            if (name.startsWith('admin/')) {
                page.layout = AdminLayout;
            }
            if (name.startsWith('auth/')) {
                page.layout = AuthLayout;
            }
        }
        return page;
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);

        const app = new Vue({
            store,
            render: h => h(App, props),
        }).$mount(el)

        store.$app = app;
    },
})

