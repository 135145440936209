<template>
  <AccountLayout>
    <div>
      <h2 class="mb-1">My Profile</h2>
      <p class="text-dim mb-4">
        Fill in your details below to update your profile.
      </p>
      <div class="row">
        <div class="col-lg-8">
          <form action="" method="post" @submit.prevent="submit" v-if="formSet">
            <TextBox
              name="name"
              v-model="form.name"
              :disabled="form.processing"
              :error="form.errors.name"
            />
            <TextBox
              name="email"
              v-model="form.email"
              :disabled="form.processing"
              :error="form.errors.email"
            />
            <TextBox
              name="mobile"
              v-model="form.mobile"
              :disabled="form.processing"
              :error="form.errors.mobile"
            />
            <div class="mt-4 text-center">
              <button
                class="btn btn-primary w-25"
                type="submit"
                :disabled="form.processing"
                @click.prevent="submit"
              >
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  v-if="form.processing"
                ></div>
                <span v-else>Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AccountLayout>
</template>
<script>
import AccountLayout from "./layout.vue";
export default {
  components: {
    AccountLayout,
  },
  props: {
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl);
    },
  },
};
</script>