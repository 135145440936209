var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Screen',[_c('AppBar',{attrs:{"title":"Orders","showBackButton":false}}),_vm._v(" "),_c('Breadcrumbs',{attrs:{"title":"Orders"}}),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.data.data.length > 0)?[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-0"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_vm._v(" "),_c('th',[_vm._v("Order ID")]),_vm._v(" "),_c('th',[_vm._v("User")]),_vm._v(" "),_c('th',[_vm._v("Product")]),_vm._v(" "),_c('th',[_vm._v("Address")]),_vm._v(" "),_c('th',[_vm._v("Qty")]),_vm._v(" "),_c('th',[_vm._v("Price")]),_vm._v(" "),_c('th',[_vm._v("Status")]),_vm._v(" "),_c('th',[_vm._v("Created")]),_vm._v(" "),_c('th',[_vm._v("Updated")]),_vm._v(" "),_c('th',[_vm._v("Action")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.data.data),function(item,i){return _c('tr',{key:item.id},[_c('td',[_vm._v("\n                  "+_vm._s(i + 1 + (_vm.data.meta.current_page - 1) * _vm.data.meta.per_page)+"\n                ")]),_vm._v(" "),_c('td',[_c('small',{domProps:{"textContent":_vm._s(item.order_id)}})]),_vm._v(" "),_c('td',[_c('a',{attrs:{"href":_vm.$route('admin.users.edit', {
                        id: item.user.id,
                      }),"target":"_blank","rel":"noopener noreferrer"},domProps:{"textContent":_vm._s(item.user.name)}}),_vm._v(" "),_c('small',{staticClass:"d-block"},[_c('a',{attrs:{"href":'mailto:' + item.user.email},domProps:{"textContent":_vm._s(item.user.email)}}),_vm._v("\n                    •\n                    "),_c('a',{attrs:{"href":'tel:' + item.user.mobile},domProps:{"textContent":_vm._s(item.user.mobile)}})])]),_vm._v(" "),_c('td',[_c('a',{staticClass:"wrap-text-2",attrs:{"href":_vm.$route('home.products.show', {
                        slug: item.product.slug,
                      }),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n                    "+_vm._s(item.product.title)+"\n                  ")])]),_vm._v(" "),_c('td',[_c('small',{domProps:{"textContent":_vm._s(item.address_line_1)}})]),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(item.quantity)}}),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(item.price_formatted)}}),_vm._v(" "),_c('td',[_c('span',{staticClass:"badge badge-pill badge-primary",class:{
                      'badge-warning': item.status_formatted === 'Pending',
                      'badge-success': item.status_formatted === 'Delivered',
                      'badge-danger': item.status_formatted === 'Cancelled',
                    },staticStyle:{"font-size":"0.8rem"},domProps:{"textContent":_vm._s(item.status_formatted)}})]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.created_at_human))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.updated_at_human))]),_vm._v(" "),_c('td',[_c('button',{staticClass:"btn btn-light btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.showOrderDetails(item)}}},[_vm._v("\n                    More info\n                  ")])])])}),0)])]),_vm._v(" "),_c('Pagination',{attrs:{"rawData":_vm.data.meta,"onClick":_vm.pageChange}})]:_c('NoData',{attrs:{"title":"No orders found"}},[_c('span')])],2)]),_vm._v(" "),_c('OrderDetails',{attrs:{"orderDetails":_vm.orderDetails},on:{"save":_vm.updateData,"close":_vm.modalClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }