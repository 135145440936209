<template>
  <div class="left-navigation">
    <div class="header">
      <div class="profile-pic">
        <ProfilePic />
      </div>
      <div class="name" v-text="user.name"></div>
      <div class="email text-dim" v-text="user.email"></div>
    </div>
    <div class="nav-links">
      <inertia-link
        :href="$route(link.href)"
        v-for="(link, i) in links"
        :key="i"
        :class="{
          active: $route().current(link.activeCheck || link.href),
        }"
      >
        <span class="inner">
          <component
            :is="link.icon"
            :weight="
              $route().current(link.activeCheck || link.href)
                ? 'fill'
                : 'duotone'
            "
            style="font-size: 1.1rem"
          />
          <span v-text="link.name"></span>
        </span>
        <small class="fa fa-arrow-right"></small>
      </inertia-link>
      <inertia-link :href="$route('auth.logout')" method="post" as="button">
        <span class="inner text-danger">
          <PhSignOut weight="bold" style="font-size: 1.1rem" />
          <span class="font-weight-bold">Logout</span>
        </span>
        <small class="fa fa-arrow-right"></small>
      </inertia-link>
    </div>
  </div>
</template>
<script>
import {
  PhUser,
  PhSignOut,
  PhShieldCheck,
  PhSliders,
  PhCreditCard,
} from "phosphor-vue";

import ProfilePic from "@/icons/profilePic.vue";

export default {
  components: {
    ProfilePic,
    PhSignOut,
  },
  data() {
    return {
      links: [
        {
          name: "Profile",
          href: "home.account.profile",
          icon: PhUser,
        },
        {
          name: "Change password",
          href: "home.account.changePassword",
          icon: PhShieldCheck,
        },
        {
          name: "Preferences",
          href: "home.account.preferences",
          icon: PhSliders,
        },
        {
          name: "Orders",
          href: "home.account.orders",
          icon: PhCreditCard,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$page.props.user;
    },
  },
};
</script>
<style lang="scss" scoped>
.left-navigation {
  position: sticky;
  top: 110px;
  .header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .profile-pic {
      margin-bottom: 0.6rem;
      img,
      svg {
        height: 8rem;
        width: 8rem;
        object-fit: cover;
        border-radius: 50%;
        .primary-fill {
          fill: var(--primary);
        }
      }
    }
    .name {
      margin-bottom: 0.2rem;
      font-size: 1.3rem;
      font-weight: 600;
      font-family: var(--font-family-primary);
    }
    .email {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: var(--font-family-primary);
    }
  }
  .nav-links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.8rem;
    a,
    button {
      flex: 1;
      background: var(--card-color);
      border-radius: var(--border-radius);
      padding: 0.8rem 1rem;
      box-shadow: var(--box-shadow);
      width: 100%;
      color: var(--dark);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: var(--transition);
      font-weight: 500;
      border: none;
      .inner {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }
      .inner,
      small {
        opacity: 0.8;
        transition: var(--transition);
        &.text-danger {
          opacity: 1;
        }
      }

      &:hover {
        .inner,
        small {
          opacity: 1;
        }
      }
      &.active {
        background: var(--primary);
        color: var(--primary-contrast);
        .inner,
        small {
          opacity: 1;
        }
        &:hover {
          background: var(--primary-dark);
          color: var(--primary-contrast);
        }
      }
    }
  }
}
</style>