<template>
  <div class="container">
    <div class="text-center">
      <h2 class="font-weight-bold">Our Social Media Accounts</h2>
      <p class="text-muted mb-4 pb-1">Follow us to get latest updates</p>
    </div>
    <div class="row">
      <div
        class="col item mb-3 mb-lg-0 text-center whatsapp"
        v-if="settings.whatsapp"
      >
        <a :href="settings.whatsapp" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-whatsapp"></i>
          </div>
          <div class="name">whatsapp</div>
          <div class="username">@{{ getUserName(settings.whatsapp) }}</div>
        </a>
      </div>
      <div
        class="col item mb-3 mb-lg-0 text-center facebook"
        v-if="settings.facebook"
      >
        <a :href="settings.facebook" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-facebook"></i>
          </div>
          <div class="name">facebook</div>
          <div class="username">@{{ getUserName(settings.facebook) }}</div>
        </a>
      </div>
      <div
        class="col item mb-3 mb-lg-0 text-center instagram"
        v-if="settings.instagram"
      >
        <a :href="settings.instagram" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-instagram"></i>
          </div>
          <div class="name">instagram</div>
          <div class="username">@{{ getUserName(settings.instagram) }}</div>
        </a>
      </div>
      <div
        class="col item mb-3 mb-lg-0 text-center twitter"
        v-if="settings.twitter"
      >
        <a :href="settings.twitter" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-twitter"></i>
          </div>
          <div class="name">twitter</div>
          <div class="username">@{{ getUserName(settings.twitter) }}</div>
        </a>
      </div>
      <div
        class="col item mb-3 mb-lg-0 text-center linkedin"
        v-if="settings.linkedin"
      >
        <a :href="settings.linkedin" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-linkedin"></i>
          </div>
          <div class="name">linkedin</div>
          <div class="username">@{{ getUserName(settings.linkedin) }}</div>
        </a>
      </div>
      <div
        class="col item mb-3 mb-lg-0 text-center youtube"
        v-if="settings.youtube"
      >
        <a :href="settings.youtube" target="_blank" rel="noopener noreferrer">
          <div class="icon">
            <i class="fab fa-youtube"></i>
          </div>
          <div class="name">youtube</div>
          <div class="username">@{{ getUserName(settings.youtube) }}</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    settings() {
      return this.$page.props.config.settings;
    },
  },
  methods: {
    getUserName(url) {
      // get final part of url
      return url.split("/").pop();
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  border-right: 2px solid var(--lighter);

  --color: 37, 211, 102;
  --color-contrast: #fff;

  &:last-child {
    border-right: none;
  }

  &.whatsapp {
    --color: 18, 140, 126;
    --color-contrast: #fff;
  }

  &.facebook {
    --color: 59, 89, 152;
    --color-contrast: #fff;
  }

  &.instagram {
    --color: 255, 64, 129;
    --color-contrast: #fff;
  }

  &.twitter {
    --color: 0, 172, 237;
    --color-contrast: #fff;
  }

  &.linkedin {
    --color: 0, 119, 181;
    --color-contrast: #fff;
  }

  &.youtube {
    --color: 255, 0, 0;
    --color-contrast: #fff;
  }

  .icon {
    width: 4.4rem;
    height: 4.2rem;
    background-color: rgba(var(--color), 0.1);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: var(--transition);
    i {
      font-size: 2rem;
      color: rgba(var(--color));
      transition: var(--transition);
    }
  }
  a {
    display: block;
    padding: 0 1.8rem;
    @media (max-width: 576px) {
      padding: 0 1rem;
    }
    &:hover {
      .icon {
        background-color: rgba(var(--color));
        i {
          color: var(--color-contrast);
        }
      }
    }
  }
  .name {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--dark);
  }
  .username {
    font-size: 0.9rem;
    color: var(--dark);
    opacity: 0.7;
  }
}
</style>