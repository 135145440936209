<template>
  <Container class="categories">
    <SectionHeader
      title="Shop by Category"
      :button="$route('home.categories')"
    />
    <div class="position-relative mx-md-2 px-md-5">
      <div
        class="swiper-button-prev d-none d-md-flex"
        ref="swiper-button-prev"
      ></div>
      <div
        class="swiper-button-next d-none d-md-flex"
        ref="swiper-button-next"
      ></div>
      <div class="swiper" ref="swiper-container" v-if="categories.length">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div
            class="text-center swiper-slide"
            v-for="category in categories"
            :key="category.id"
          >
            <inertia-link
              :href="
                $route('home.products.index', {
                  category: category.slug,
                })
              "
              class="category-item"
            >
              <img
                v-lazy="category.image_url"
                :alt="category.name"
                role="link"
              />
              <p>{{ category.name }}</p>
            </inertia-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination position-relative pt-2 pt-sm-3"
      ref="swiper-pagination"
    ></div>
  </Container>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination]);

export default {
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    initSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
      this.swiper = new Swiper(this.$refs["swiper-container"], {
        speed: 400,
        loop: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: this.$refs["swiper-pagination"],
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },
        breakpoints: {
          //   1650: {
          //     slidesPerView: 6,
          //   },
          1400: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 4,
          },
          820: {
            slidesPerView: 3,
          },
          470: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 2,
          },
        },
      });
    },
  },
  mounted() {
    if (this.categories.length > 0) {
      this.initSwiper();
    }

    this.$root.$on("categories:loaded", () => {
      setTimeout(() => {
        this.initSwiper();
      }, 100);
    });
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
  },
};
</script>
<style lang="scss" scoped>
.categories {
  .category-item {
    display: block;
    color: var(--dark);
    &:hover {
      color: var(--primary-dark);
    }
    img {
      height: 250px;
      width: 250px;
      max-width: 100%;
      object-fit: cover;
      background: var(--white);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
    }
    p {
      font-size: 1.2rem;
      margin-top: 0.6rem;
      margin-bottom: 0;
      transition: var(--transition);
      line-height: 1.2;
      font-weight: 600;
    }
    @media (max-width: 576px) {
      img {
        height: 200px;
        width: 200px;
      }
      p {
        font-size: 0.8px;
      }
    }
    @media (max-width: 470px) {
      img {
        height: 140px;
        width: 140px;
        max-width: 100%;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
</style>