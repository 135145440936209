<template>
  <div class="form-wrapper">
    <inertia-link :href="$route('home.index')">
      <img
        :src="asset('images/logo.png')"
        :alt="app_name() + 'logo'"
        class="logo"
      />
    </inertia-link>
    <h1 class="mb-1">Register</h1>
    <p class="mb-4 text-dim">
      Welcome to {{ app_name() }}! Please enter your details below to register.
    </p>
    <form action="" method="post" @submit.prevent="submit">
      <transition name="form-error" appear>
        <div
          class="alert alert-danger font-weight-bold"
          role="alert"
          v-if="errorMsg"
          v-text="errorMsg"
        ></div>
      </transition>
      <TextBox
        name="name"
        v-model="form.name"
        :disabled="form.processing"
        :error="form.errors.name"
      />
      <TextBox
        name="email"
        v-model="form.email"
        :disabled="form.processing"
        :error="form.errors.email"
      />
      <TextBox
        name="mobile_number"
        v-model="form.mobile"
        :disabled="form.processing"
        :error="form.errors.mobile"
      />
      <TextBox
        name="password"
        type="password"
        v-model="form.password"
        :disabled="form.processing"
        :error="form.errors.password"
      />
      <TextBox
        name="password_confirmation"
        type="password"
        v-model="form.password_confirmation"
        :disabled="form.processing"
        :error="form.errors.password_confirmation"
      />
      <button
        class="mt-4 btn btn-primary w-100"
        type="submit"
        :disabled="form.processing"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="form.processing"
        ></div>
        <span v-else>Register</span>
      </button>
    </form>
    <div
      class="mt-4 d-flex justify-content-between flex-wrap align-items-center"
      style="gap: 0.5rem"
    >
      <p class="mb-0">
        Already have an account?
        <inertia-link :href="$route('auth.login')" class="font-weight-bold"
          >Login</inertia-link
        >
      </p>
      <p class="mb-0">
        <inertia-link :href="$route('home.index')" class="font-weight-bold"
          >Back to home</inertia-link
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        mobile: null,
        password: null,
        password_confirmation: null,
      }),
      alerts: {
        type: null,
        text: null,
      },
    };
  },
  methods: {
    submit() {
      this.form.post(this.$route("auth.register.submit"), {
        onSuccess: () => {
          this.form.password = null;
          this.form.password_confirmation = null;
        },
        onError: () => {
          this.form.password = null;
          this.form.password_confirmation = null;
        },
      });
    },
  },
  computed: {
    errorMsg() {
      return this.$page.props.flash.error_msg;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-wrapper {
  width: 600px;
  max-width: 100%;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background: var(--card-color);
  @media (max-width: 576px) {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  .logo {
    width: 168px;
    margin-bottom: 2rem;
  }
  h1 {
    font-size: 2rem;
  }
}
</style>