<template>
  <Screen>
    <AppBar title="Products" :showBackButton="false">
      <inertia-link
        :href="$route('admin.products.create')"
        class="btn btn-sm btn-primary"
      >
        <i class="fa fa-plus"></i>
        <span class="d-none d-app-inline">Add new</span>
      </inertia-link>
    </AppBar>
    <Breadcrumbs title="Products" />
    <div class="card">
      <div class="card-body">
        <SearchBar
          @onSubmit="searchSubmit"
          @onClear="searchCleared"
          v-model="searchInput"
        />
        <template v-if="data.data.length > 0">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Thumbnail</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Stock status</th>
                  <th>Featured status</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in data.data" :key="item.id">
                  <td>
                    {{
                      i + 1 + (data.meta.current_page - 1) * data.meta.per_page
                    }}
                  </td>
                  <td>
                    <img
                      v-lazy="item.thumbnail_url + '&nocache=1'"
                      class="img-rounded-sm"
                    />
                  </td>
                  <th>
                    <a
                      :href="$route('home.products.show', { slug: item.slug })"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ item.title }}</a
                    >
                  </th>
                  <td>
                    <span class="d-block"
                      ><span class="text-dim">CP:</span>
                      {{
                        item.cost_price ? item.cost_price_formatted : "-"
                      }}</span
                    >
                    <span class="d-block"
                      ><span class="text-dim">SP:</span>
                      {{ item.selling_price_formatted }}</span
                    >
                  </td>
                  <th>
                    <StatusSwitch
                      :dataid="item.id"
                      :status="item.is_active"
                      action="productStatus"
                    />
                  </th>
                  <th>
                    <StatusSwitch
                      labelText="Yes/No"
                      :dataid="item.id"
                      :status="item.in_stock"
                      action="productStockStatus"
                    />
                  </th>
                  <th>
                    <StatusSwitch
                      labelText="Yes/No"
                      :dataid="item.id"
                      :status="item.is_featured"
                      action="productFeaturedStatus"
                    />
                  </th>
                  <td>{{ item.created_at_human }}</td>
                  <td>{{ item.updated_at_human }}</td>
                  <td>
                    <inertia-link
                      class="btn btn-sm btn-light"
                      :href="
                        $route('admin.products.edit', {
                          id: item.id,
                        })
                      "
                      >Edit</inertia-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination :rawData="data.meta" :onClick="pageChange" />
        </template>
        <NoData title="No products added yet" v-else>
          <inertia-link
            :href="$route('admin.products.create')"
            class="btn btn-primary"
            ><i class="fa fa-plus"></i> Add new</inertia-link
          >
        </NoData>
      </div>
    </div>
  </Screen>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
  },
  watch: {
    search: {
      immediate: true,
      handler(value) {
        this.searchInput = value;
      },
    },
  },
  data() {
    return {
      searchInput: "",
    };
  },
  mounted() {},
  methods: {
    searchSubmit(search) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: search,
        })
      );
    },
    searchCleared() {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          search: "",
        })
      );
    },
    pageChange(page) {
      this.$root.$emit("hold-transition", null);
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          page: page,
          search: this.searchInput,
        })
      );
    },
  },
  computed: {},
};
</script>
