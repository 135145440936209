<template>
  <Container>
    <SectionHeader title="Our Services" />

    <h3>Medical devices Service Centre in {{ country }}</h3>
    <p>
      Devilbiss Oxygen concentrator CPAP & BiPAP Service Centre in
      {{ country }} <br />
      Devilbiss Medical Equipments such as Devilbiss BLUE CPAP, SleepCube CPAP,
      BiPAP & BiPAP ST, DeVilbiss D150 & D100 CPAP Mask, DeVilbiss iGo Portable
      Oxygen Concentrator services like repair & maintenance services can be
      done.
    </p>
    <hr />
    <h3>CPAP/BIPAP Repair Service Centre in {{ country }}:</h3>
    <p>
      We are counted amongst the trusted and the leading companies for providing
      CPAP/BIPAP and oxygen concentrator Equipment Repair and Maintenance
      Service in GCC. We provide the superior quality of Medical Equipment
      Repairs Service for repairing of medical equipment with genuine spare
      parts for enhancing the functionality of the equipments, the medical
      equipment repairs are known for their systematic execution. We render
      timely repairing of medical equipments at a nominal cost.
    </p>
    <hr />
    <h3>Respiratory Medical Equipment Service Centre Advantages:</h3>
    <ul>
      <li>Timely repair</li>
      <li>Systematic repair of medical equipment</li>
      <li>Cost effective</li>
      <li>Only authenticate spare parts used</li>
    </ul>
    <hr />
    <h3>CPAP & BiPAP Maintenance Service Centre in {{ country }}:</h3>
    <p>
      This service is provided by Mediniq medical equipment service centre for
      you as per the industry standards and recommended for maintenance purpose.
      We have hired the experts for maintenance of medical equipment who will
      know all the latest techniques of maintenance. Besides these, our service
      is easily available for you 24x7 and we are known amongst the customers
      for our timely execution of maintenance work and its effectiveness.
    </p>
    <hr />
    <h3>Respiratory/Sleep therapy Equipment Maintenance:</h3>
    <ul>
      <li>Maintaining of home healthcare equipment's periodically</li>
      <li>
        We are 24*7 on call services or we provide our dedicated biomedical
        engineer at customer place.
      </li>
      <li>Repairing of the medical equipments</li>
      <li>Maintenance of the Gas Management Services</li>
      <li>
        Providing of Preventive Maintenance services as per the periodic
        maintenance and providing the required documents
      </li>
    </ul>
    <p>
      At Mediniq, we are also providing repair service and also maintenance
      service for the old portable advanced medical equipments.
    </p>
    <hr />
    <h3>Respiratory CPAP/BIPAP Oxygen for Rental</h3>
    <h4>
      Cylinders, Portable Oxygen Concentrators, Stationary Oxygen Concentrators,
      CPAP and BIPAP
    </h4>
    <p>
      The medical needs of travelers can vary greatly - some individuals may
      only need oxygen while sleeping, while others may require it during
      physical activity, and still others may need oxygen assistance with every
      breath. <br />
      We have everything from traditional gaseous oxygen cylinders in a variety
      of types and sizes to the newest Personal Oxygen Concentrators (POCs)
      available for rent. Additionally, our staff is trained to ask the right
      questions up front to ensure passengers get the portable oxygen rental
      they need and, most importantly, delivered using a method they know and
      understand.
    </p>
    <hr />
    <h5>Packages Include</h5>
    <ul>
      <li>Machine</li>
      <li>Humidifier</li>
      <li>Tube</li>
      <li>Filter</li>
      <li>Carry Bag</li>
      <li>Power Cable</li>
    </ul>
    <p>
      With our rental system, you can choose a hire all our CPAP, BiPAP
      (BiLevel) and Oxygen machines. All our CPAP rental packages will come
      complete with everything you need <br />
      Please call if you require help with renting a CPAP machine
    </p>
    <p>
      Whether you rent or buy a CPAP, we will remain available 24/7 during your
      CPAP experience. Our CPAP trained representatives will ensure your
      immediate and long term needs are met through education and acclimation.
    </p>
  </Container>
</template>
<script>
export default {
  computed: {
    country() {
      return this.$page.props.config.country;
    },
  },
};
</script>