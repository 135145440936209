<template>
  <Screen>
    <AppBar title="Page title" :showBackButton="false" />
    <Breadcrumbs title="Page title" />
  </Screen>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>