<template>
  <div class="item">
    <div class="img">
      <img
        v-lazy="data.product.thumbnail_url"
        :alt="data.product.title"
        :class="{ grayscale: !data.product.in_stock }"
      />
    </div>
    <div class="content">
      <inertia-link
        :href="
          $route('home.products.show', {
            slug: data.product.slug,
          })
        "
      >
        <h5 class="wrap-text-2 mb-1" v-text="data.product.title"></h5>
      </inertia-link>
      <p class="wrap-text-2 text-dim mb-2">
        <small v-text="data.product.short_description"></small>
      </p>
      <div class="price-wrapper" v-if="data.product.in_stock">
        <div class="price">
          <div class="striked-wrapper" v-if="data.product.cost_price">
            <span
              v-text="data.product.cost_price_formatted"
              class="striked"
            ></span>
            <b class="text-success" v-if="data.product.discount"
              >{{ data.product.discount }}% off</b
            >
          </div>
          <div
            class="current"
            v-text="data.product.selling_price_formatted"
          ></div>
        </div>
        <div class="cart-buttons">
          <div class="cart-buttons">
            <button
              class="cart-helper-btn"
              type="button"
              @click.stop.prevent="removeFromCart"
              :disabled="addToCartLoading"
            >
              -
            </button>
            <div
              class="cart-count"
              style="padding: 0 0.4rem"
              v-text="cartCount"
            ></div>
            <button
              class="cart-helper-btn"
              type="button"
              @click.stop.prevent="addToCart"
              :disabled="addToCartLoading"
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div class="alert alert-danger mb-0" v-else>
        Product is currently not in stock
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addToCartLoading: false,
    };
  },
  methods: {
    addToCart() {
      this.addToCartLoading = true;
      this.$store.dispatch("addToCart", this.data.product.id).then(() => {
        this.addToCartLoading = false;
      });
    },
    removeFromCart() {
      this.addToCartLoading = true;
      this.$store.dispatch("removeFromCart", this.data.product.id).then(() => {
        this.addToCartLoading = false;
      });
    },
  },
  computed: {
    cartCount() {
      return this.countInCart(this.data.product.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  gap: 0.8rem;
  background: var(--card-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 1.2rem;
  .img {
    img {
      width: 10rem;
      height: 100%;
      object-fit: cover;
      border-radius: calc(var(--border-radius) - 2%);
      &.grayscale {
        filter: grayscale(100%);
      }
    }
  }
  .content {
    flex: 1;
    .price-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.5rem;
      .price {
        .striked-wrapper {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 80%;
          .striked {
            opacity: 0.6;
            margin-right: 0.4rem;
            text-decoration: line-through;
          }
        }
        .current {
          font-weight: bold;
          color: var(--dark);
        }
      }
      .cart-buttons {
        background: var(--bg-color);
        color: var(--dark);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius-sm);
        overflow: hidden;
        .cart-helper-btn {
          background: var(--primary);
          border: none;
          color: var(--primary-contrast);
          padding: 0 0.6rem;
          font-size: 1.2rem;
        }
        .cart-count {
          padding: 0 0.4rem;
          pointer-events: none;
        }
      }
    }
  }
}
</style>