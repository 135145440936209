<template>
  <FormLayout>
    <form action="" method="post" @submit.prevent="submit" v-if="formSet">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="category_id">Category</label>
            <AdvanceSelect
              class="advance-dpdown"
              :options="categories.data"
              :reduce="(item) => item.id"
              id="category_id"
              label="name"
              placeholder="Select category"
              :disabled="form.processing"
              :class="{ 'is-invalid': form.errors.category_id }"
              v-model="form.category_id"
            >
            </AdvanceSelect>
            <transition name="form-error">
              <div
                class="error"
                v-if="form.errors.category_id"
                v-text="form.errors.category_id"
              ></div>
            </transition>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="sub_category_id">Sub category</label>
            <AdvanceSelect
              class="advance-dpdown"
              :options="subCats"
              :reduce="(item) => item.id"
              id="sub_category_id"
              label="name"
              placeholder="Select sub category"
              :disabled="form.processing"
              :class="{ 'is-invalid': form.errors.sub_category_id }"
              v-model="form.sub_category_id"
            >
            </AdvanceSelect>
            <transition name="form-error">
              <div
                class="error"
                v-if="form.errors.sub_category_id"
                v-text="form.errors.sub_category_id"
              ></div>
            </transition>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <TextBox
            name="title"
            v-model="form.title"
            :disabled="form.processing"
            :error="form.errors.title"
          />
        </div>
        <div class="col-lg-6">
          <TextBox
            label="Title in arabic"
            name="title_ar"
            v-model="form.title_ar"
            :disabled="form.processing"
            :error="form.errors.title_ar"
            lang="ar"
          />
        </div>
      </div>
      <TextBox
        name="slug"
        v-model="form.slug"
        :disabled="form.processing"
        :error="form.errors.slug"
      />
      <TextBox
        name="seo_tags"
        v-model="form.seo_tags"
        :disabled="form.processing"
        :error="form.errors.seo_tags"
      />
      <div class="row">
        <div class="col-lg-6">
          <TextBox
            name="cost_price"
            v-model="form.cost_price"
            :disabled="form.processing"
            :error="form.errors.cost_price"
            helperText="Cost price for this product. This price of the product before any discount."
          />
        </div>
        <div class="col-lg-6">
          <TextBox
            name="selling_price"
            v-model="form.selling_price"
            :disabled="form.processing"
            :error="form.errors.selling_price"
            helperText="The actual price of the product"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <TextBox
            type="textarea"
            name="short_description"
            v-model="form.short_description"
            :disabled="form.processing"
            :error="form.errors.short_description"
          />
        </div>
        <div class="col-lg-6">
          <TextBox
            type="textarea"
            label="Short description  in arabic"
            name="short_description_ar"
            v-model="form.short_description_ar"
            :disabled="form.processing"
            :error="form.errors.short_description_ar"
            lang="ar"
          />
        </div>
      </div>
      <TextBox
        type="textarea"
        name="description"
        v-model="form.description"
        :disabled="form.processing"
        :error="form.errors.description"
        :inputClass="'summernote'"
      />
      <TextBox
        type="textarea"
        label="Description in arabic"
        name="description_ar"
        v-model="form.description_ar"
        :disabled="form.processing"
        :error="form.errors.description_ar"
        lang="ar"
        :inputClass="'summernote-ar'"
      />
      <FileInput
        name="thumbnail"
        v-model="form.thumbnail"
        :fileUrl="form.thumbnail_url"
        :disabled="form.processing"
        :error="form.errors.thumbnail"
      />
      <div class="row">
        <div class="col">
          <StatusRadio
            label="Is featured"
            :radioLabel="['Yes', 'No']"
            name="is_featured"
            v-model="form.is_featured"
            :disabled="form.processing"
            :error="form.errors.is_featured"
          />
        </div>
        <div class="col">
          <StatusRadio
            label="In stock"
            :radioLabel="['Yes', 'No']"
            name="in_stock"
            v-model="form.in_stock"
            :disabled="form.processing"
            :error="form.errors.in_stock"
          />
        </div>
        <div class="col">
          <StatusRadio
            v-model="form.is_active"
            :disabled="form.processing"
            :error="form.errors.is_active"
          />
        </div>
      </div>
      <div class="mt-4 text-center">
        <button
          class="btn btn-primary w-25"
          type="submit"
          :disabled="form.processing"
          @click.prevent="submit"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="form.processing"
          ></div>
          <span v-else>Save</span>
        </button>
      </div>
    </form>
  </FormLayout>
</template>
<script>
import _ from "lodash";
import FileInput from "@/components/form/fileInput.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "@/helpers/summernote/summernote-bs4.min.js";
import "@/helpers/summernote/summernote-bs4.min.css";

export default {
  components: {
    AdvanceSelect: vSelect,
    FileInput,
    FormLayout: () =>
      import(
        /* webpackChunkName: "admin-productform-layout" */ "./formLayout.vue"
      ),
  },
  props: {
    categories: {
      type: Object,
      default: () => {},
    },
    subCategories: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    "form.title": {
      handler(value) {
        if (this.formSet) {
          this.form.slug = _.kebabCase(value);
        }
      },
    },
    "form.category_id": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.subCats = this.subCategories.data.filter(
            (subCategory) => subCategory.category_id == val
          );

          if (this.form.sub_category_id) {
            const exists = this.subCats.filter(
              (subCat) => subCat.id == this.form.sub_category_id
            );

            if (exists.length) {
              this.form.sub_category_id = exists[0].id;
            } else {
              this.form.sub_category_id = null;
            }
          } else {
            this.form.sub_category_id = null;
          }
        }
      },
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
      subCats: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      this.form.thumbnail = null;
      setTimeout(() => {
        this.formSet = true;
        this.initSummernote();
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl);
    },
    initSummernote() {
      // wait for render
      setTimeout(() => {
        $(".summernote").summernote({
          callbacks: {
            onChange: (contents, $editable) => {
              this.form.description = contents;
            },
          },
        });
        $(".summernote-ar").summernote({
          callbacks: {
            onChange: (contents, $editable) => {
              this.form.description_ar = contents;
            },
          },
        });
      }, 100);
    },
  },
  computed: {},
};
</script>
