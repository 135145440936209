<template>
  <div>
    <Container>
      <SectionHeader title="About us" />
      <p style="font-size: 1.1rem">
        Lifekare Medical Equipment Trading LLC is dedicated to providing you
        with advanced medical and homecare equipment from the world's trusted
        brand names. We are professionals in medical equipment products in
        Dubai. Our dedicated team concentrates on client contentment by
        supplying the best Medical equipment in Dubai. Our collaborations with
        suppliers and manufacturers allow us to deliver the products you use
        most at a minimal cost.
      </p>
      <div class="row mb-5">
        <div class="col-lg-6">
          <p class="mb-1" style="opacity: 0.8">
            The variety of equipment we deal with includes - Respiratory care,
            Sleep Therapy, Ventilators, ICU Equipment's, Emergency support
            equipment, Diagnostic equipment, ENT equipment, Gynecological
            devices, Health center furnishing equipment, homecare equipment,
            Operation Theater support equipment, Physical fitness equipment,
            Physiotherapy, as well as rehabilitation devices, sanitizing tools,
            X-ray devices, Medical consumables.
          </p>
          <p class="mb-0" style="opacity: 0.8">
            Our Products are Manufactured by world-class equipment companies
            like BMC, Inogen, Devilbiss, HUM, Sunset, Sanrai, and more.
          </p>
        </div>
        <div class="col-lg-6">
          <p class="mb-1" style="opacity: 0.8">
            We are determined to make healthcare centers and hospitals readily
            accessible to new and advanced equipment that competently diagnoses
            and treats disease. When choosing the ideal clinical equipment
            representatives in Dubai, Lifekare strives to comprehend your
            requirements and satisfy you with the perfect items. If you believe
            that working with us should be a pleasurable experience, you can
            rely on us to obtain pleasant and trusted service, quick shipment,
            and complete satisfaction.
          </p>
          <p class="mb-0" style="opacity: 0.8">
            We proceed our march towards excellence by flourishing on innovation
            and innovations to fulfill the ever-growing requirements of the UAE
            healthcare domain. Lifekare is a reputation for Aiding Health care
            Professionals and Institutions to Enhance their Services in line
            with International Criteria.
          </p>
        </div>
      </div>
      <hr />
      <h2 class="mb-2" style="color: var(--dark)">Our Values</h2>
      <p class="mb-0" style="opacity: 0.8">
        To market and distribute high-quality products that exceed our client's
        expectations by a team influenced by understanding and values.
      </p>
      <p class="mb-2" style="opacity: 0.8">
        At Mediniq Healthcare, We take care of sales, service and rentals of
        portable and advanced medical equipments such as Portable Oxygen
        Concentrator, CPAP & BI PAP Masks, Oximeters etc...
      </p>
      <ul style="opacity: 0.8">
        <li>Inspire essential trust, consider well as a team effort</li>
        <li>Deal with honesty, honesty as well as transparency</li>
        <li>Create client-concentrated systems and techniques</li>
      </ul>
      <hr />
      <h2 class="mb-2" style="color: var(--dark)">The Lifekare Benefit</h2>
      <p class="font-weight-bold mb-1">Wide Option</p>
      <p style="opacity: 0.8">
        We collaborate with 100 + companies, consisting of the globe's leading
        brand names, providing clinical products.
      </p>
      <p class="font-weight-bold mb-1">Unequaled Experience</p>
      <p style="opacity: 0.8">
        Innovative HealthTech platform offering wise purchase, competitive rates
        & expense savings with our premium item offerings
      </p>
      <p class="font-weight-bold mb-1">Unparalleled Reach</p>
      <p style="opacity: 0.8">
        Connecting to all locations in the United Arab Emirates via our
        committed network
      </p>
      <p class="font-weight-bold mb-1">Consumer First</p>
      <p style="opacity: 0.8">
        We have a specialized and highly educated group of specialists who work
        hard each day with a customer-first approach.
      </p>
      <hr />
    </Container>
    <SocialLinks />
    <Reviews />
    <Partners />
  </div>
</template>
<script>
export default {
  components: {
    Partners: () =>
      import(
        /* webpackChunkName: "home-shared-partners" */ "./shared/partners"
      ),
    Reviews: () =>
      import(/* webpackChunkName: "home-shared-reviews" */ "./shared/reviews"),
    SocialLinks: () =>
      import(
        /* webpackChunkName: "home-shared-social-links" */ "./shared/socialLinks"
      ),
  },
};
</script>