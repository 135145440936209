<template>
  <Container>
    <div class="row">
      <div class="col-lg-4 col-xl-3 d-lg-block d-none">
        <MoreLinks />
      </div>
      <div class="col-lg-8 col-xl-9">
        <slot />
      </div>
    </div>
  </Container>
</template>
<script>
import MoreLinks from "./moreLinks.vue";

export default {
  components: {
    MoreLinks,
  },
};
</script>
<style lang="scss" scoped>
.left-navigation {
  position: sticky;
  top: 110px;
  .header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .profile-pic {
      margin-bottom: 0.6rem;
      img {
        height: 8rem;
        width: 8rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name {
      margin-bottom: 0.2rem;
      font-size: 1.3rem;
      font-weight: 600;
      font-family: var(--font-family-primary);
    }
    .email {
      font-size: 0.9rem;
      font-weight: 400;
      font-family: var(--font-family-primary);
    }
  }
  .nav-links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.8rem;
    a {
      flex: 1;
      background: var(--card-color);
      border-radius: var(--border-radius);
      padding: 0.8rem 1rem;
      box-shadow: var(--box-shadow);
      width: 100%;
      color: var(--dark);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: var(--transition);
      font-weight: 500;
      .inner {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }
      .inner,
      small {
        opacity: 0.8;
        transition: var(--transition);
      }
      &:hover {
        .inner,
        small {
          opacity: 1;
        }
      }
      &.active {
        background: var(--primary);
        color: var(--primary-contrast);
        .inner,
        small {
          opacity: 1;
        }
        &:hover {
          background: var(--primary-dark);
          color: var(--primary-contrast);
        }
      }
    }
  }
}
</style>