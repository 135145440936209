import APIS from '@/helpers/apis';

const state = {
  isAuthenticated: false,
  user: {
    id: null,
    name: 'User name',
    email: null,
    role: null,
    mobile: null,
  },
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user,
};

const mutations = {
  isAuthenticated: (state, payload) => (state.isAuthenticated = payload),
  user: (state, payload) => (state.user = payload),
}

const actions = {
  login({ commit }, payload) {
    if (payload) {
      localStorage.setItem("isAuthenticated", true);
      commit('isAuthenticated', true);
      commit('user', payload);
    }
  },

  updateUser({ commit }, payload) {
    if (payload) {
      commit('user', payload);
    }
  },

  async signOut({ commit, dispatch }) {
    commit('isAuthenticated', false);
    await axios.post(APIS.auth.logout);
    return dispatch('fetchAuthUser');
  },

  async fetchAuthUser({ commit }) {
    return axios.get(APIS.myAccount.profile.base).then((response) => {
      commit('isAuthenticated', true);
      commit('user', response.data.data);
    }).catch((error) => {
      if (error.response.status == 401) {
        commit('isAuthenticated', false);
        commit('user', null);
      }
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
