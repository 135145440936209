<template>
  <Screen>
    <AppBar :title="title" />
    <Breadcrumbs
      :links="[
        {
          to: $route('admin.categories.subcategories.index'),
          title: 'Sub categories',
        },
      ]"
      :title="title"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <form
              action=""
              method="post"
              @submit.prevent="submit"
              v-if="formSet"
            >
              <div class="form-group">
                <label for="category_id">Category</label>
                <AdvanceSelect
                  class="advance-dpdown"
                  :options="categories.data"
                  :reduce="(item) => item.id"
                  id="category_id"
                  label="name"
                  placeholder="Select category"
                  :disabled="form.processing"
                  :class="{ 'is-invalid': form.errors.category_id }"
                  v-model="form.category_id"
                >
                </AdvanceSelect>
                <transition name="form-error">
                  <div
                    class="error"
                    v-if="form.errors.category_id"
                    v-text="form.errors.category_id"
                  ></div>
                </transition>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <TextBox
                    name="name"
                    v-model="form.name"
                    :disabled="form.processing"
                    :error="form.errors.name"
                  />
                </div>
                <div class="col-lg-6">
                  <TextBox
                    label="Name in arabic"
                    name="name_ar"
                    v-model="form.name_ar"
                    :disabled="form.processing"
                    :error="form.errors.name_ar"
                    lang="ar"
                  />
                </div>
              </div>
              <TextBox
                name="slug"
                v-model="form.slug"
                :disabled="form.processing"
                :error="form.errors.slug"
              />
              <FileInput
                name="image"
                v-model="form.image"
                :fileUrl="form.image_url"
                :disabled="form.processing"
                :error="form.errors.image"
              />
              <FileInput
                name="icon"
                v-model="form.icon"
                :fileUrl="form.icon_url"
                :disabled="form.processing"
                :error="form.errors.icon"
              />
              <StatusRadio
                v-model="form.is_active"
                :disabled="form.processing"
                :error="form.errors.is_active"
              />
              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-25"
                  type="submit"
                  :disabled="form.processing"
                  @click.prevent="submit"
                >
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="form.processing"
                  ></div>
                  <span v-else>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Screen>
</template>
<script>
import _ from "lodash";
import FileInput from "@/components/form/fileInput.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    AdvanceSelect: vSelect,
    FileInput,
  },
  props: {
    categories: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    formData: {
      type: [Object, Array],
      default: () => {},
    },
    submitUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    "form.name": {
      handler(value) {
        if (this.formSet) {
          this.form.slug = _.kebabCase(value);
        }
      },
    },
  },
  data() {
    return {
      form: {},
      formSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form = this.$inertia.form(this.formData);
      this.form.icon = null;
      this.form.image = null;
      setTimeout(() => {
        this.formSet = true;
      });
    });
  },
  methods: {
    submit() {
      this.form.post(this.submitUrl, {
        onSuccess: () => this.form.reset(),
      });
    },
  },
  computed: {},
};
</script>
