<template>
  <App>
    <div class="login-wrapper">
      <template name="fade" mode="out-in">
        <slot />
      </template>
    </div>
  </App>
</template>
<script>
import App from "../App.vue";
export default {
  components: {
    App,
  },
};
</script>
<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 2rem;

  @media (max-width: 576px) {
    padding-right: 0;
    padding-left: 0;
    display: block;
  }
}
</style>