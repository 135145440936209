<template>
  <App>
    <Header />
    <main :class="{ 'is-fixed': isFixed }">
      <slot />
    </main>
    <Footer />
    <dark-light-switch />
    <CallFab />
    <BottomNav />
  </App>
</template>
<script>
import App from "../../App.vue";

export default {
  components: {
    App,
    Header: () =>
      import(/* webpackChunkName: "layouts-home-header" */ "./header.vue"),
    Footer: () =>
      import(/* webpackChunkName: "layouts-home-footer" */ "./footer.vue"),
    DarkLightSwitch: () =>
      import(
        /* webpackChunkName: "components-darkLightSwitch" */ "@/components/darkLightSwitch.vue"
      ),
    BottomNav: () =>
      import(
        /* webpackChunkName: "layouts-home-bottom-nav" */ "./bottomNav.vue"
      ),
    CallFab: () =>
      import(/* webpackChunkName: "layouts-home-call-fab" */ "./callFab.vue"),
  },
  data() {
    return {
      isFixed: false,
    };
  },
  mounted() {
    this.$root.$on("topNavPosition", (isFixed) => {
      this.isFixed = isFixed;
    });

    const defaultCurrency = {
      code: this.$page.props.config.default_currency,
      display_name: `${this.$page.props.config.default_currency} - ${this.$page.props.config.default_currency_symbol}`,
    };

    this.$store.dispatch("fetchCurrencies", defaultCurrency).then(() => {
      this.$root.$emit("currencies:loaded");
    });

    this.$store.dispatch("fetchCategories").then(() => {
      this.$root.$emit("categories:loaded");
    });

    if (this.isAuthenticated) {
      this.$store.dispatch("fetchCart").then(() => {
        this.$root.$emit("cart:updated");
      });
    }
  },
  computed: {
    isAuthenticated() {
      return this.$page.props.isAuthenticated;
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  min-height: 100vh;
  min-height: calc(
    calc(var(--vh, 1vh) * 100) - var(--top-nav-height, 80px) -
      var(--bottom-nav-height, 56px)
  );
  &.is-fixed {
    padding-top: calc(var(--top-nav-height, 80px) + 2px);
  }
}
</style>