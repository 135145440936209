<template>
  <AccountLayout>
    <div>
      <h2 class="mb-4">Manage your preferences</h2>
      <hr />
      <form action="">
        <h4 class="mb-3">Appearance</h4>
        <div class="row">
          <div class="col-auto">
            <div class="custom-control custom-radio mb-3">
              <input
                type="radio"
                id="device_theme-1"
                name="device_theme"
                class="custom-control-input"
                value="system-default"
                v-model="theme"
              />
              <label class="custom-control-label" for="device_theme-1"
                >Device theme (System default)</label
              >
            </div>
          </div>
          <div class="col-auto">
            <div class="custom-control custom-radio mb-3">
              <input
                type="radio"
                id="device_theme-2"
                name="device_theme"
                class="custom-control-input"
                value="light"
                v-model="theme"
              />
              <label class="custom-control-label" for="device_theme-2"
                >Light theme</label
              >
            </div>
          </div>
          <div class="col-auto">
            <div class="custom-control custom-radio mb-0">
              <input
                type="radio"
                id="device_theme-3"
                name="device_theme"
                class="custom-control-input"
                value="dark"
                v-model="theme"
              />
              <label class="custom-control-label" for="device_theme-3"
                >Dark theme</label
              >
            </div>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary btn-sm" @click.prevent="changeTheme">
              Save
            </button>
          </div>
        </div>
        <hr />
        <h4 class="mb-3">Currency</h4>
        <div class="row">
          <div class="col col-lg-6">
            <div class="form-group">
              <select
                class="form-control"
                id="currency-select"
                v-model="currency"
              >
                <option
                  v-for="currency in currencies"
                  :key="currency.code"
                  :value="currency.code"
                  v-text="currency.display_name"
                ></option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary btn-sm"
              @click.prevent="changeCurrency"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </AccountLayout>
</template>
<script>
import AccountLayout from "./layout.vue";
export default {
  components: {
    AccountLayout,
  },
  data() {
    return {
      theme: "light",
      currency: "USD",
    };
  },
  mounted() {
    this.theme = this.$store.getters.appTheme;
    this.currency = this.$page.props.config.currency || "USD";

    this.$root.$on("app:themechanged", (theme) => {
      if (theme && this.theme != theme) {
        this.theme = theme;
      }
    });
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("setTheme", this.theme).then(() => {
        this.$root.$emit("app:themechanged", this.theme);
      });
    },
    changeCurrency() {
      axios
        .post(this.$route("home.api.set-app-currency"), {
          app_currency: this.currency,
        })
        .then((response) => {
          if (response.data.status) {
            this.success_alert(response.data.success_msg);
          } else {
            this.error_alert(response.data.error_msg);
          }
        })
        .catch((error) => {
          this.error_alert(
            error.response.data.error_msg || "Something went wrong"
          );
        });
    },
  },
  computed: {
    currencies() {
      return this.$store.getters.currencies;
    },
  },
};
</script>