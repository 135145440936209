<template>
  <AccountLayout>
    <div>
      <h2 class="mb-4">My Orders</h2>
      <template v-if="data.data.length > 0">
        <div class="list">
          <div class="item" v-for="item in data.data" :key="item.id">
            <div class="details">
              <div class="detail">
                <div class="heading">Order ID</div>
                <div class="content" v-text="item.order_id"></div>
              </div>
              <div class="detail">
                <div class="heading">Order Status</div>
                <div class="content">
                  <i
                    class="fa fa-circle"
                    :class="{
                      'text-warning': item.status === 'pending',
                      'text-success': item.status == 'delivered',
                      'text-danger': item.status == 'cancelled',
                    }"
                  ></i
                  >&nbsp;&nbsp;<span v-text="item.status_formatted"></span>
                </div>
              </div>
              <div class="detail">
                <div class="heading">Order Placed</div>
                <div class="content" v-text="item.created_at_human"></div>
              </div>
              <div class="detail">
                <div class="heading">Order Updated</div>
                <div class="content" v-text="item.updated_at_human"></div>
              </div>
            </div>
            <div class="product">
              <img
                v-lazy="item.product.thumbnail_url"
                :alt="item.product.title"
              />
              <div class="content" style="flex: 1">
                <a href="#" class="title">
                  <h5 class="mb-2 wrap-text-1" v-text="item.product.title"></h5>
                </a>
                <hr class="my-1" />
                <p class="meta mb-0">Ship to:</p>
                <p class="mb-0">
                  <span v-text="item.address_line_1"></span>
                  <small class="mx-1 text-muted">&bullet;</small>
                  <span v-text="item.address_line_2"></span>
                </p>
                <p class="mb-2">
                  <span v-text="item.city"></span>
                  <small class="mx-1 text-muted">&bullet;</small>
                  <span v-text="item.state"></span>
                  <small class="mx-1 text-muted">&bullet;</small>
                  <span v-text="item.zip"></span>
                </p>
                <p class="mb-0">
                  <span class="meta">Qty:</span>
                  <b v-text="item.quantity"></b>
                </p>
                <p class="mb-1">
                  <span class="meta">Price:</span>
                  <b v-text="item.ordered_price_formatted"></b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Pagination :rawData="data.meta" :onClick="pageChange" />
      </template>
      <NoData title="You haven't ordered anything yet!" v-else>
        <inertia-link
          :href="$route('home.products.index')"
          class="btn btn-primary"
          >Continue shopping&nbsp;<small class="fa fa-arrow-right"></small
        ></inertia-link>
      </NoData>
    </div>
  </AccountLayout>
</template>
<script>
import AccountLayout from "./layout.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AccountLayout,
  },
  methods: {
    pageChange(page) {
      this.$inertia.visit(
        this.$route(this.$route().current(), {
          page: page,
        })
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  background: var(--bg-color);
  border: 2px solid var(--lighter);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  padding: 1rem;
  .details {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
    border-bottom: 2px solid var(--lighter);
    flex-wrap: wrap;
    .detail {
      @media (max-width: 576px) {
        flex: 0 0 calc(50% - 0.25rem);
      }
    }
    .heading {
      opacity: 0.6;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
    .content {
      font-weight: 600;
      color: var(--dark);
    }
  }
  .product {
    display: flex;
    gap: 1rem;
    img {
      width: 10rem;
      height: 100%;
      max-height: 10rem;
      object-fit: cover;
      border-radius: calc(var(--border-radius) - 2%);
    }
    @media (max-width: 576px) {
      gap: 0.6rem;
      img {
        width: 6rem;
        max-height: 6rem;
      }
    }
    .meta {
      text-transform: uppercase;
      opacity: 0.6;
      font-size: 90%;
      font-weight: 500;
    }
  }
}
</style>