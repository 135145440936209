<template>
  <div
    class="add-to-cart"
    :class="{ disabled: addToCartLoading }"
    v-if="isAuthenticated && inStock"
    @click.stop=""
  >
    <transition name="zoom-in-out" mode="out-in">
      <div v-if="cartCount > 0" class="cart-buttons" @click.stop="">
        <button
          class="cart-helper-btn"
          type="button"
          @click.stop.prevent="removeFromCart"
          :disabled="addToCartLoading"
        >
          -
        </button>
        <div
          class="cart-count"
          style="padding: 0 0.4rem"
          v-text="cartCount"
        ></div>
        <button
          class="cart-helper-btn"
          type="button"
          @click.stop.prevent="addToCart"
          :disabled="addToCartLoading"
        >
          +
        </button>
      </div>
      <button
        class="add-to-cart-btn"
        v-tooltip="'Add to cart'"
        :disabled="addToCartLoading"
        @click.prevent.stop="addToCart"
        v-else
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="addToCartLoading"
        ></span>
        <PhShoppingCartSimple weight="fill" v-else />
      </button>
    </transition>
  </div>
</template>
<script>
import { PhShoppingCartSimple } from "phosphor-vue";

export default {
  components: {
    PhShoppingCartSimple,
  },
  props: {
    productId: {
      type: [Number, String],
      default: 0,
    },
    inStock: {
      type: [Boolean, Number],
      default: true,
    },
  },
  data() {
    return {
      addToCartLoading: false,
    };
  },
  methods: {
    addToCart() {
      this.addToCartLoading = true;
      this.$store.dispatch("addToCart", this.productId).then(() => {
        this.$root.$emit("cart:updated");
        this.addToCartLoading = false;
      });
    },
    removeFromCart() {
      this.addToCartLoading = true;
      this.$store.dispatch("removeFromCart", this.productId).then(() => {
        this.$root.$emit("cart:updated");
        this.addToCartLoading = false;
      });
    },
  },
  computed: {
    isAuthenticated() {
      return this.$page.props.isAuthenticated;
    },
    cartCount() {
      return this.countInCart(this.productId);
    },
  },
};
</script>
<style lang="scss" scoped>
.add-to-cart {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 5;
  .add-to-cart-btn {
    height: 2.2rem;
    width: 2.2rem;
    background: var(--primary);
    border: none;
    color: var(--primary-contrast);
    display: grid;
    place-content: center;
    font-size: 1.2rem;
    border-radius: var(--border-radius-sm);
    box-shadow: var(--box-shadow-primary);
    transition: all 0.3s ease;
    &:hover,
    &:focus {
      background: var(--primary-dark);
      color: var(--primary-contrast);
    }
  }
  .cart-buttons {
    background: var(--bg-color);
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-sm);
    overflow: hidden;
    .cart-helper-btn {
      background: var(--primary);
      border: none;
      color: var(--primary-contrast);
      padding: 0 0.6rem;
      font-size: 1.2rem;
    }
    .cart-count {
      padding: 0 0.4rem;
      pointer-events: none;
    }
  }
  &.disabled {
    cursor: not-allowed;
    .cart-buttons {
      background: var(--disabled-color);
      color: var(--disabled-color-contrast);
      cursor: not-allowed;
    }
  }
}

.cart-transition-enter-active,
.cart-transition-leave-active {
  transition: all 0.3s ease;
}

.cart-transition-enter,
.cart-transition-leave-active {
  opacity: 0;
  transition: all 0.3s ease;
}
</style>