<template>
  <inertia-link
    class="item"
    :href="
      $route('home.products.index', {
        category: category.slug,
      })
    "
    :key="category.id"
    @mouseover="mouseover()"
    @mouseleave="mouseleave()"
  >
    <div class="details">
      <!-- <img
        v-lazy="category.icon_url"
        class="icon"
        :alt="`${category.name} icon`"
      /> -->
      <div class="text wrap-text-2" v-text="category.name"></div>
    </div>
    <a class="link" href="">
      <i class="fas fa-arrow-right"></i>
    </a>
    <transition name="fade">
      <div
        class="preview"
        v-if="showPreview"
        @mouseover="mouseover()"
        @mouseleave="mouseleave()"
      >
        <div class="heading mb-4">
          <inertia-link
            :href="
              $route('home.products.index', {
                category: category.slug,
              })
            "
            class="link"
          >
            <h4 class="mb-0" style="flex: 1">{{ category.name }}</h4>
            <i class="fas fa-arrow-right"></i>
          </inertia-link>
        </div>
        <div class="sub-cats">
          <inertia-link
            v-for="subCategory in category.sub_categories"
            :key="subCategory.id"
            :href="
              $route('home.products.index', {
                sub_category: subCategory.slug,
              })
            "
            class="sub-cat"
          >
            <img
              v-lazy="subCategory.icon_url"
              class="icon"
              :alt="`${subCategory.name} icon`"
            />
            <div class="text wrap-text-2" v-text="subCategory.name"></div>
          </inertia-link>
        </div>
      </div>
    </transition>
  </inertia-link>
</template>
<script>
export default {
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showPreview: false,
    };
  },
  mounted() {
    this.showPreview = false;
    // if (this.category.id == 1) {
    //   this.showPreview = true;
    // }
    this.$root.$on("herocategories:hideall", (id) => {
      if (id !== this.category.id) {
        this.showPreview = false;
      }
    });
  },
  methods: {
    mouseover() {
      if (!this.category.sub_categories || !this.category.sub_categories.length)
        return;
      this.showPreview = true;
      this.$root.$emit("herocategories:hideall", this.category.id);
    },
    mouseleave() {
      this.showPreview = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--lighter);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .details {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .icon {
      height: 3rem;
      width: 3rem;
      object-fit: contain;
    }
    .text {
      font-size: 1.1rem;
      font-family: var(--font-family-primary);
      opacity: 0.6;
      color: var(--dark);
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      line-height: 1;
    }

    @media (max-width: 1200px) {
      .text {
        font-size: 1rem;
      }
      .icon {
        height: 2.6rem;
        width: 2.6rem;
      }
    }
  }

  &:hover {
    .details .text {
      opacity: 1;
      color: var(--primary);
    }
  }
}

.preview {
  padding: 1rem;
  position: absolute;
  right: 0;
  height: 380px;
  width: 300px;
  top: 0;
  transform: translateX(82%);
  z-index: 50;
  background: var(--card-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-hover);
  color: var(--font-color);
  cursor: default;
  overflow: auto;
  .heading {
    .link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
      color: var(--dark);
      font-weight: 500;
      transition: var(--transition);
      &:hover {
        color: var(--primary);
      }
      h4 {
        font-size: 1.2rem;
      }
    }
  }
  .sub-cats {
    display: flex;
    flex-wrap: wrap;
    .sub-cat {
      width: 50%;
      margin: 0;
      border-top: 1px solid var(--lighter);
      border-right: 1px solid var(--lighter);
      min-height: 72px;
      padding: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      text-align: center;
      &:nth-child(1),
      &:nth-child(2) {
        border-top: none;
      }
      &:nth-child(2n + 0) {
        border-right: none;
      }
      .icon {
        height: 2.5rem;
        width: 2.5rem;
        object-fit: contain;
      }
      .text {
        font-size: 0.9rem;
        opacity: 0.6;
        color: var(--dark);
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        line-height: 1;
      }
      &:hover {
        .text {
          opacity: 1;
          color: var(--primary);
        }
      }
    }
  }
}
</style>