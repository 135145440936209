<template>
  <div class="app">
    <slot />
  </div>
</template>
<script>
import "@fortawesome/fontawesome-free/css/all.css";
const seo = {
  title: defaultSeo.title,
  description: defaultSeo.description,
  keywords: defaultSeo.keywords,
  image: defaultSeo.image,
  canonical: defaultSeo.canonical,
  locale: defaultSeo.locale,
  robots: defaultSeo.robots,
  "og:site_name": defaultSeo["og:site_name"],
  "og:type": defaultSeo["og:type"],
  "og:url": defaultSeo["og:url"],
};

export default {
  watch: {
    "$page.props.flash": {
      immediate: true,
      handler(data) {
        if (data.use_sweetalert) {
          if (data.success_msg) {
            this.success_alert(data.success_msg);
          }
          if (data.error_msg) {
            this.error_alert(data.error_msg);
          }
          if (data.info_msg) {
            this.info_alert(data.info_msg);
          }
        }
      },
    },
    "$page.props.seoData": {
      immediate: true,
      handler(data) {
        if (data) {
          this.meta = { ...seo, ...data };
        } else {
          this.meta = seo;
        }
        this.updateSeoTags();
      },
    },
  },
  data() {
    return {
      meta: seo,
      matchMediaObject: null,
      sideNavOpen: false,
    };
  },
  mounted() {
    this.matchMediaObject = window.matchMedia("(min-width: 1055px)");
    this.mediQueryChange(this.matchMediaObject);

    this.matchMediaObject.addEventListener("change", (event) =>
      this.mediQueryChange(event)
    );
  },
  methods: {
    mediQueryChange(screen) {
      this.$store.dispatch("viewportUpdate", screen.matches);
    },
    updateSeoTags() {
      // titles
      document.title = this.meta.title;
      this.selectAndUpdateTags(
        [
          'meta[property="og:title"]',
          'meta[property="twitter:title"]',
          'meta[name="twitter:title"]',
        ],
        this.meta.title
      );

      // description
      this.selectAndUpdateTags(
        [
          'meta[name="description"]',
          'meta[property="og:description"]',
          'meta[property="twitter:description"]',
          'meta[name="twitter:description"]',
        ],
        this.meta.description
      );

      // keywords
      this.selectAndUpdateTags(
        [
          'meta[name="keywords"]',
          'meta[property="og:keywords"]',
          'meta[property="twitter:keywords"]',
          'meta[name="twitter:keywords"]',
        ],
        this.meta.keywords
      );

      // image
      this.selectAndUpdateTags(
        [
          'meta[property="og:image"]',
          'meta[property="twitter:image"]',
          'meta[name="twitter:image"]',
          'meta[name="twitter:image:src"]',
        ],
        this.meta.image
      );

      // canonical
      this.selectAndUpdateTags(
        ['link[rel="canonical"]', 'meta[property="og:url"]'],
        this.meta.canonical
      );

      // locale
      this.selectAndUpdateTags(
        [
          'meta[property="og:locale"]',
          'meta[property="og:locale:alternate"]',
          'meta[property="og:locale:alternate"]',
        ],
        this.meta.locale
      );
      document.querySelector("html").setAttribute("lang", this.meta.locale);

      // robots
      this.selectAndUpdateTags(['meta[name="robots"]'], this.meta.robots);

      // og:site_name
      this.selectAndUpdateTags(
        ['meta[property="og:site_name"]'],
        this.meta["og:site_name"]
      );

      // og:type
      this.selectAndUpdateTags(
        ['meta[property="og:type"]'],
        this.meta["og:type"]
      );

      // og:url
      this.selectAndUpdateTags(
        ['meta[property="og:url"]'],
        this.meta["og:url"]
      );
    },
    selectAndUpdateTags(tags = [], value) {
      tags.forEach((element) => {
        const ele = document.querySelector(element);
        if (ele) {
          ele.setAttribute("content", value);
        } else {
          // create new meta tag
          const meta = document.createElement("meta");
          meta.setAttribute("content", value);
          meta.setAttribute(
            "name",
            element.replace("meta[", "").replace("]", "")
          );
          document.head.appendChild(meta);
        }
      });
    },
  },
};
</script>